import React from 'react';
// Pages
import Users from "../pages/Users";
import MobileOperator from "../pages/MobileOperator";
import CustomerCategory from '../pages/CustomerCategory';
import Roles from '../pages/Roles';
import Banks from '../pages/Banks';
import BankBranches from '../pages/BankBranches';
import MessageHistory from '../pages/MessageHistory';
import ErrorManagement from '../pages/ErrorManagement';
import Rules from '../pages/Rules';
import SystemAlerts from '../pages/SystemAlerts';
import Permissions from '../pages/Permissions';
import UserDetails from '../pages/UserDetails';

export const tabs = [
    {
        path: '/users',
        permissionName: 'manage.users.list',
        component: <Users />
    },
    {
        path: '/roles',
        permissionName: 'manage.roles.list',
        component: <Roles />
    },
    {
        path: '/permissions',
        permissionName: 'manage.permission.list',
        component: <Permissions />
    },
    {
        path: '/mno-management',
        permissionName: 'manage.mno.list',
        component: <MobileOperator />
    },
    {
        path: '/error-management',
        permissionName: 'error.management.view.error.list',
        component: <ErrorManagement />
    },
    {
        path: '/bank-management',
        permissionName: 'manage.banks.list',
        component: <Banks />
    },
    {
        path: '/customer-category',
        permissionName: 'manage.customer.category.list',
        component: <CustomerCategory />
    },
    {
        path: '/rules',
        permissionName: 'manage.rules.list',
        component: <Rules />
    },
    {
        path: '/system-alerts',
        permissionName: 'system.alert.list',
        component: <SystemAlerts />
    },
    {
        path: '/message-history',
        permissionName: 'view.message.history',
        component: <MessageHistory />
    },
    {
        path: '/user-details',
        permissionName: 'manage.users.view',
        component: <UserDetails />
    }
]