import React from 'react';

const Spinner = props => {
    return (
        <span className="spinner">
            Loading...
        </span>
    );
};

export default Spinner;