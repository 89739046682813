var helper = {
    titleCaseForTabs: function (str) {
        str = str.split(/(?=[A-Z])/).join(' ');
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    },
    tableHeadersFormater: function (title, path) {
        if (title === 'indexId') {
            return 'ID';
        }
        if (path === '/mno-management' || path === '/bank-management' || path === '/customer-category' || path === '/error-management' || path === '/system-alerts') {
            var mnoConditioner = title === 'name' ? 'MNO Name' : title === 'code' ? 'MNO Key' : this.titleCaseForTabs(title);
            var banksConditioner = title === 'name' ? 'Bank Name' : title === 'swift' ? 'Bank SWIFT' : title === 'code' ? 'Bank Code' : 'cobDuration' ? 'COB Duration' : this.titleCaseForTabs(title);
            var customerConditioner = title === 'name' ? 'Category Name' : title === 'description' ? 'Category Description' : this.titleCaseForTabs(title);
            var errorConditioner = title === 'key' ? 'Error Code' : title === 'translationEN' ? 'EN' : title === 'translationAL' ? 'AL' : this.titleCaseForTabs(title);
            var systemConditioner = title === 'event' ? 'Event Type' : title === 'channel' ? 'Delivery Channel(s)' : title === 'subjectAL' ? 'Subject AL' : title === 'subjectEN' ? 'Subject EN' : title === 'contentAL' ? 'Content AL' : title === 'contentEN' ? 'Content EN' : this.titleCaseForTabs(title);

            var titleConditioner = path === '/mno-management' ?
                mnoConditioner : path === '/bank-management' ?
                    banksConditioner : path === '/customer-category' ?
                        customerConditioner : path === '/error-management' ?
                            errorConditioner : path === '/system-alerts' ?
                                systemConditioner : this.titleCaseForTabs(title);
            return titleConditioner;
        } else {
            const uppercaseWords = ["id", "msisdn", "mno", "cbs", "kuik", "iban", "mpay", "rbal", "udid"];
            let splitStr = this.titleCaseForTabs(title);
            uppercaseWords.map(word => {
                var regEx = new RegExp(word, "ig");
                if (splitStr.toLowerCase().includes(word)) {
                    splitStr = splitStr.replace(regEx, word.toUpperCase());
                }
            });
            return splitStr.charAt(0).toUpperCase() + splitStr.slice(1);
        }
    },
    displayErrors: function (data, toast) {
        if (data.messages) {
            toast.error(data.messages[0].text);
        } else {
            Object.values(data.errors).map(err => {
                toast.error(err[0]);
            });
        }
    }
}

module.exports = helper;