import React, { Component } from 'react';
import config from '../config';
import List from "../components/Table/List";
import HeaderContent from "../components/HeaderContent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Divider, Form, Accordion, Icon, Dropdown } from "semantic-ui-react";
import Dialog from '../components/UI/Dialog';
import { Formik } from 'formik';
import * as Yup from 'yup';

class SystemAlerts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            event: '',
            subjectAL: '',
            subjectEN: '',
            contentAL: '',
            contentEN: '',
            channel: '',
            isLoading: false,
            activeIndex: 0
        };

        this.callbackFunction = this.callbackFunction.bind(this);
        this.updateHandler = this.updateHandler.bind(this);
        this.deactiveHandler = this.deactiveHandler.bind(this);
    }

    callbackFunction(childData, identifier) {
        this.setState({ event: childData.event });
        this.setState({ subjectAL: childData.subjectAL });
        this.setState({ subjectEN: childData.subjectEN });
        this.setState({ contentAL: childData.contentAL });
        this.setState({ contentEN: childData.contentEN });
        this.setState({ channel: childData.channel }, function () {
            if (identifier === 'update') {
                this.setState({ isUpdateDialog: true });
            } else if (identifier === 'delete') {
                this.setState({ isDeleteDialog: true });
            }
        });
    };

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    updateHandler(values) {
        const { event, subjectAL, subjectEN, contentAL, contentEN, channel } = values;
        let channelSms = false, channelEmail = false, channelPush = false;

        if (channel.includes('Email')) {
            channelEmail = true;
        }
        if (channel.includes('Sms')) {
            channelSms = true;
        }
        if (channel.includes('Push')) {
            channelPush = true;
        }

        this.setState({ isLoading: true });
        fetch(`${config.adminBaseURL}/notificationtemplate/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "event": event,
                "subjectAL": subjectAL,
                "subjectEN": subjectEN,
                "contentAL": contentAL,
                "contentEN": contentEN,
                "channelSms": channelSms,
                "channelEmail": channelEmail,
                "channelPush": channelPush
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ isLoading: false });
            if (data.success) {
                this.setState({ isUpdateDialog: false });
                this.setState({ event: '' });
                this.setState({ subjectAL: '' });
                this.setState({ subjectEN: '' });
                this.setState({ contentAL: '' });
                this.setState({ contentEN: '' });
                this.setState({ channel: '' });
                toast.success('Alert updated successfully!');
                this.refreshChild();
            } else {
                toast.error(data.description);
            }
        })
    }

    deactiveHandler() {
        this.setState({ isLoading: true });
        fetch(`${config.adminBaseURL}/notificationtemplate/deactivate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "event": this.state.event
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ isLoading: false });
            if (data.success) {
                this.setState({ isDeleteDialog: false });
                this.setState({ event: '' });
                this.setState({ subjectAL: '' });
                this.setState({ subjectEN: '' });
                this.setState({ contentAL: '' });
                this.setState({ contentEN: '' });
                this.setState({ channel: '' });
                toast.success('Alert deactivated successfully!');
                this.refreshChild();
            } else {
                toast.error(data.description);
            }
        })
    }

    handleChannelChange = (e, data) => {
        const newData = data.value.toString();
        this.setState({channel: newData});
    }

    render() {
        const { event, subjectAL, subjectEN, contentAL, contentEN, channel, isUpdateDialog, isLoading, isDeleteDialog, activeIndex } = this.state;

        return (
            <React.Fragment>
                <HeaderContent />
                <Divider />
                <List setRefresh={refresh => this.refreshChild = refresh} parentCallback={this.callbackFunction} />

                <Formik
                    enableReinitialize
                    initialValues={{
                        event: event, subjectAL: subjectAL, subjectEN: subjectEN, contentAL: contentAL, contentEN: contentEN, channel: channel
                    }}
                    onSubmit={values => {
                        this.updateHandler(values);
                    }}
                    validationSchema={Yup.object().shape({
                        subjectAL: Yup.string()
                            .required('This field is required.'),
                        subjectEN: Yup.string()
                            .required('This field is required.'),
                        contentAL: Yup.string()
                            .required('This field is required.'),
                        contentEN: Yup.string()
                            .required('This field is required.')
                    })}
                >
                    {({ handleChange, handleBlur, errors, touched, values, handleSubmit }) => (
                        <Dialog
                            open={isUpdateDialog}
                            size={'large'}
                            onCancel={() => {
                                this.setState({ isUpdateDialog: false });
                                this.setState({ event: '' });
                                this.setState({ subjectAL: '' });
                                this.setState({ subjectEN: '' });
                                this.setState({ contentAL: '' });
                                this.setState({ contentEN: '' });
                                this.setState({ channel: '' });
                            }}
                            onConfirm={handleSubmit}
                            title={'Edit Alert'}
                            confirmText={'Save'}
                            loading={isLoading}
                        >
                            <Form>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <h4>Details</h4>
                                        <Form.Group widths='equal'>
                                            <Form.Field>
                                                <Form.Input fluid label="Event" type='text' name='event' placeholder='Event' value={values.event} readOnly />
                                                {errors.event && touched.event ? (
                                                    <span className='formik-error-msg'>{errors.event}</span>
                                                ) : null}
                                            </Form.Field>
                                        </Form.Group>
                                        <Form.Group widths='equal'>
                                            <Form.Field>
                                                <label>Delivery Channel(s)</label>
                                                <Dropdown placeholder='Delivery Channel(s)'
                                                    defaultValue={channel.split(',')}
                                                    fluid multiple selection
                                                    options={[
                                                        { key: 'Sms', text: 'Sms', value: 'Sms' },
                                                        { key: 'Email', text: 'Email', value: 'Email' },
                                                        { key: 'Push', text: 'Push', value: 'Push' }
                                                    ]} 
                                                    onChange={this.handleChannelChange} />
                                            </Form.Field>
                                        </Form.Group>
                                    </Form.Field>
                                    <Form.Field>
                                        <h4>Subject and Content</h4>
                                        <Accordion styled>
                                            <Accordion.Title
                                                active={activeIndex === 0}
                                                index={0}
                                                onClick={this.handleClick}
                                            >
                                                <Icon name='dropdown' />
                                                English
                                            </Accordion.Title>
                                            <Accordion.Content active={activeIndex === 0}>
                                                <Form.Group widths='equal'>
                                                    <Form.Field>
                                                        <Form.Input fluid label="Subject (email)" type='text' name='subjectEN' placeholder='Subject EN' value={values.subjectEN} onChange={handleChange} onBlur={handleBlur} />
                                                        {errors.subjectEN && touched.subjectEN ? (
                                                            <span className='formik-error-msg'>{errors.subjectEN}</span>
                                                        ) : null}
                                                    </Form.Field>
                                                </Form.Group>
                                                <Form.Group widths='equal'>
                                                    <Form.Field>
                                                        <Form.TextArea fluid label="Content (email)" type='text' name='contentEN' placeholder='Content EN' value={values.contentEN} onChange={handleChange} onBlur={handleBlur} />
                                                        {errors.contentEN && touched.contentEN ? (
                                                            <span className='formik-error-msg'>{errors.contentEN}</span>
                                                        ) : null}
                                                    </Form.Field>
                                                </Form.Group>
                                            </Accordion.Content>

                                            <Accordion.Title
                                                active={activeIndex === 1}
                                                index={1}
                                                onClick={this.handleClick}
                                            >
                                                <Icon name='dropdown' />
                                                Albanian
                                            </Accordion.Title>
                                            <Accordion.Content active={activeIndex === 1}>
                                                <Form.Group widths='equal'>
                                                    <Form.Field>
                                                        <Form.Input fluid label="Subject (email)" type='text' name='subjectAL' placeholder='Subject EN' value={values.subjectAL} onChange={handleChange} onBlur={handleBlur} />
                                                        {errors.subjectAL && touched.subjectAL ? (
                                                            <span className='formik-error-msg'>{errors.subjectAL}</span>
                                                        ) : null}
                                                    </Form.Field>
                                                </Form.Group>
                                                <Form.Group widths='equal'>
                                                    <Form.Field>
                                                        <Form.TextArea fluid label="Content (email)" type='text' name='contentAL' placeholder='Content EN' value={values.contentAL} onChange={handleChange} onBlur={handleBlur} />
                                                        {errors.contentAL && touched.contentAL ? (
                                                            <span className='formik-error-msg'>{errors.contentAL}</span>
                                                        ) : null}
                                                    </Form.Field>
                                                </Form.Group>
                                            </Accordion.Content>
                                        </Accordion>
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </Dialog>
                    )}
                </Formik>

                <Dialog
                    open={isDeleteDialog}
                    onCancel={() => {
                        this.setState({ isDeleteDialog: false });
                        this.setState({ event: '' });
                        this.setState({ subjectAL: '' });
                        this.setState({ subjectEN: '' });
                        this.setState({ contentAL: '' });
                        this.setState({ contentEN: '' });
                        this.setState({ channel: '' });
                    }}
                    onConfirm={this.deactiveHandler}
                    title={'Deactive Alert'}
                    confirmText={'Yes'}
                    loading={isLoading}
                ><p>Are you sure you want to deactivate the selected alert?</p></Dialog>

                <ToastContainer />
            </React.Fragment>
        );
    }
};

export default SystemAlerts;