import React, { Component, Fragment } from 'react';
import List from "../components/Table/List";
import HeaderContent from "../components/HeaderContent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Divider, Form, Tab, Icon, Select, Table, Grid } from "semantic-ui-react";
import Dialog from '../components/UI/Dialog';
import { Formik } from 'formik';
import * as Yup from 'yup';
import config from '../config';
import { displayErrors } from '../helper';
import { connect } from 'react-redux';

const initialState = {
    isOpen: false,
    isUpdateDialog: false,
    isDeleteDialog: false,
    isLoading: false,
    activeIndex: 0,
    channel: 'Select Channel',
    customerCategory: 'Select Customer',
    operation: 'Select Operation',
    currency: '',
    ruleId: '',
    startDate: null,
    endDate: null,
    priority: '',
    maxAmount: null,
    maxAmountDaily: null,
    maxAmountLifetime: null,
    maxAmountMonthly: null,
    maxAmountWeekly: null,
    maxCountDaily: null,
    maxCountLifetime: null,
    maxCountMonthly: null,
    maxCountWeekly: null,
    minAmount: null,
    categoryList: []
}

class Rules extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;

        this.callbackFunction = this.callbackFunction.bind(this);
    }

    callbackFunction(childData, identifier) {
        const { ruleId, channel, customerCategoryId, currency, startDate, endDate, priority, transferType, maxAmount, maxAmountDaily, maxAmountLifetime, maxAmountMonthly, maxAmountWeekly, maxCountDaily, maxCountLifetime, maxCountMonthly, maxCountWeekly, minAmount } = childData;
        this.setState({ ruleId, currency, channel, customerCategory: customerCategoryId, startDate, endDate, priority, operation: transferType, maxAmount, maxAmountDaily, maxAmountLifetime, maxAmountMonthly, maxAmountWeekly, maxCountDaily, maxCountLifetime, maxCountMonthly, maxCountWeekly, minAmount });

        if (identifier === 'update') {
            this.getCustomerCategoryList();
            this.setState({ isUpdateDialog: true });
        } else if (identifier === 'delete') {
            this.setState({ isDeleteDialog: true });
        }
    };

    openCreatePopup = () => {
        this.setState({ isOpen: true });
        this.getCustomerCategoryList();
    };

    handleTabChange = (e, { activeIndex }) => {
        this.setState({ activeIndex });
    };

    nextTabHandler = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                activeIndex: prevState.activeIndex + 1
            }
        });
        if (this.state.activeIndex > 2) {
            this.setState({ activeIndex: 0 })
        }
    }

    prevTabHandler = () => {
        if (this.state.activeIndex === 0) {
            this.setState({ activeIndex: 4 });
        }
        this.setState(prevState => {
            return {
                ...prevState,
                activeIndex: prevState.activeIndex - 1
            }
        });
    }

    onSelectChange = (e, data) => {
        this.setState({ [data.name]: data.value });
    };

    createRuleHandler = (values, resetForm) => {
        const { channel, customerCategory, operation, currency } = this.state;
        const { priority, startDate, endDate, transactionMin, transactionMax, dailyMax, dailyCount, weeklyMax, weeklyCount, monthlyMax, monthlyCount, lifetimeMaxAmount, lifetimeMaxCount } = values;

        this.setState({ isLoading: true });
        fetch(`${config.adminBaseURL}/rule/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "priority": priority,
                "minAmount": transactionMin,
                "maxAmount": transactionMax,
                "maxAmountDaily": dailyMax,
                "maxCountDaily": dailyCount,
                "maxAmountWeekly": weeklyMax,
                "maxCountWeekly": weeklyCount,
                "maxAmountMonthly": monthlyMax,
                "maxCountMonthly": monthlyCount,
                "maxAmountLifetime": lifetimeMaxAmount,
                "maxCountLifetime": lifetimeMaxCount,
                "currency": currency,
                "startDate": startDate,
                "endDate": endDate,
                "transferType": operation,
                "channel": channel,
                "customerCategoryId": customerCategory
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ isLoading: false });
            if (data.success) {
                this.setState(initialState);
                toast.success('Rule created successfully!');
                resetForm();
                this.refreshChild();
            } else {
                displayErrors(data, toast);
            }
        })
    }

    updateRuleHandler = (values) => {
        const { channel, customerCategory, operation, currency, ruleId } = this.state;
        const { priority, startDate, endDate, transactionMin, transactionMax, dailyMax, dailyCount, weeklyMax, weeklyCount, monthlyMax, monthlyCount, lifetimeMaxAmount, lifetimeMaxCount } = values;

        this.setState({ isLoading: true });
        fetch(`${config.adminBaseURL}/rule/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "ruleId": ruleId,
                "priority": priority,
                "minAmount": transactionMin,
                "maxAmount": transactionMax,
                "maxAmountDaily": dailyMax,
                "maxCountDaily": dailyCount,
                "maxAmountWeekly": weeklyMax,
                "maxCountWeekly": weeklyCount,
                "maxAmountMonthly": monthlyMax,
                "maxCountMonthly": monthlyCount,
                "maxAmountLifetime": lifetimeMaxAmount,
                "maxCountLifetime": lifetimeMaxCount,
                "currency": currency,
                "startDate": startDate,
                "endDate": endDate,
                "transferType": operation,
                "channel": channel,
                "customerCategoryId": customerCategory
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ isLoading: false });
            if (data.success) {
                this.setState(initialState);
                toast.success('Rule updated successfully!');
                this.refreshChild();
            } else {
                displayErrors(data, toast);
            }
        })
    }

    deleteRuleHandler = () => {
        this.setState({ isLoading: true });
        fetch(`${config.adminBaseURL}/rule/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "id": this.state.ruleId
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ isLoading: false });
            if (data.success) {
                this.setState(initialState);
                toast.success('Rule deleted successfully!');
                this.refreshChild();
            } else {
                toast.error(data.description);
            }
        })
    };

    getCustomerCategoryList = () => {
        this.setState({ isLoading: true });
        fetch(`${config.adminBaseURL}/category/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "pageIndex": 0,
                "pageSize": 10
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ isLoading: false, categoryList: data.value.data });
        })
    }

    displayNotNullableValues = (type, value) => {
        if (value !== null) {
            return `${type} ${value}`;
        }
        return;
    }

    render() {
        const { isOpen, isUpdateDialog, isLoading, isDeleteDialog, activeIndex, channel, customerCategory, categoryList, operation, currency, priority, startDate, endDate, maxAmount, maxAmountDaily, maxAmountLifetime, maxAmountMonthly, maxAmountWeekly, maxCountDaily, maxCountLifetime, maxCountMonthly, maxCountWeekly, minAmount } = this.state;

        return (
            <Fragment>
                <HeaderContent onClickHandler={this.openCreatePopup} buttonTitle={'Create New Rule'} showButton={true} />
                <Divider />
                <List setRefresh={refresh => this.refreshChild = refresh} parentCallback={this.callbackFunction} />

                <Formik
                    initialValues={{ priority: priority, channel: channel, customerCategory: customerCategory, operation: operation, startDate: startDate, endDate: endDate, transactionMin: minAmount, transactionMax: maxAmount, dailyMax: maxAmountDaily, dailyCount: maxCountDaily, weeklyMax: maxAmountWeekly, weeklyCount: maxCountWeekly, monthlyMax: maxAmountMonthly, monthlyCount: maxCountMonthly, lifetimeMaxAmount: maxAmountLifetime, lifetimeMaxCount: maxCountLifetime }}
                    onSubmit={(values, { resetForm }) => {
                        this.createRuleHandler(values, resetForm);
                    }}
                    validationSchema={Yup.object().shape({
                        priority: Yup.number()
                            .required('This field is required.')
                    })}
                >
                    {({ handleChange, handleBlur, errors, touched, values, handleSubmit }) => (
                        <Dialog
                            className="rule-modal"
                            open={isOpen}
                            size={'fullscreen'}
                            onCancel={() => {
                                this.setState(initialState);
                            }}
                            onConfirm={handleSubmit}
                            title={'Create New Rule'}
                            confirmText={'Create Rule'}
                            loading={isLoading}
                        >
                            <Form>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input fluid label="Priority" type='number' name='priority' placeholder='Priority' value={values.priority} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.priority && touched.priority ? (
                                            <span className='formik-error-msg'>{errors.priority}</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                                <div>
                                    <div className="control-buttons">
                                        <button className="control-btn" onClick={this.prevTabHandler}><Icon name='chevron left' /> Previous</button>
                                        <button className="control-btn" onClick={this.nextTabHandler}>Next <Icon name='chevron right' /></button>
                                    </div>
                                    <Tab
                                        menu={{ secondary: true, pointing: true }}
                                        panes={[
                                            {
                                                menuItem: 'CHANNEL',
                                                render: () => (
                                                    <Tab.Pane attached={false}>
                                                        <React.Fragment>
                                                            <Form.Group widths='equal'>
                                                                <Form.Field>
                                                                    <label>Channel</label>
                                                                    {
                                                                        this.props.user.includes('permissions.customer.management.isRbksUser') ?
                                                                            <Select
                                                                                defaultValue={0}
                                                                                options={[
                                                                                    { key: 'ron', value: 0, text: 'RPLUS' },
                                                                                ]}
                                                                                onChange={this.onSelectChange}
                                                                                style={{ borderRadius: 0, height: 50 }} /> :
                                                                            <Select
                                                                                defaultValue={channel}
                                                                                name="channel"
                                                                                options={[
                                                                                    { key: 'kuik', value: 0, text: 'KUIK' },
                                                                                    { key: 'ron', value: 1, text: 'RPLUS' },
                                                                                ]}
                                                                                onChange={this.onSelectChange}
                                                                                style={{ borderRadius: 0, height: 50 }} />
                                                                    }
                                                                    {channel === "Select Channel" && touched.channel ? (
                                                                        <span className='formik-error-msg'>This field is required.</span>
                                                                    ) : null}
                                                                </Form.Field>
                                                            </Form.Group>
                                                            <Form.Group widths='equal'>
                                                                <Form.Field>
                                                                    <label>Customer Category</label>
                                                                    <Select
                                                                        defaultValue={customerCategory}
                                                                        name="customerCategory"
                                                                        options={categoryList.map(category => {
                                                                            return { key: category.id, value: category.id, text: category.name === 'fullyFledged' ? 'Fully-Fledged' : 'Limited' }
                                                                        })}
                                                                        onChange={this.onSelectChange}
                                                                        style={{ borderRadius: 0, height: 50 }} />
                                                                    {customerCategory === "Select Customer" && touched.customerCategory ? (
                                                                        <span className='formik-error-msg'>This field is required.</span>
                                                                    ) : null}
                                                                </Form.Field>
                                                            </Form.Group>
                                                        </React.Fragment>
                                                    </Tab.Pane>
                                                ),
                                            },
                                            {
                                                menuItem: 'OPERATION',
                                                render: () => (
                                                    <Tab.Pane attached={false}>
                                                        <Form>
                                                            <Form.Group widths='equal'>
                                                                <Form.Field>
                                                                    <label>Transfer Type</label>
                                                                    <Select
                                                                        defaultValue={operation}
                                                                        clearable
                                                                        isClearable={true}
                                                                        name="operation"
                                                                        options={[
                                                                            { key: 'p2p', value: 1, text: 'P2P Transfer' },
                                                                            { key: 'balance', value: 4, text: 'Balance Enquiry' },
                                                                        ]}
                                                                        onChange={this.onSelectChange}
                                                                        style={{ borderRadius: 0, height: 50 }} />
                                                                    {operation === "Select Operation" && touched.operation ? (
                                                                        <span className='formik-error-msg'>This field is required.</span>
                                                                    ) : null}
                                                                </Form.Field>
                                                            </Form.Group>
                                                            <Form.Group widths='equal'>
                                                                <Form.Field>
                                                                    <Form.Input fluid label="Start Date" type='date' name='startDate' value={values.startDate} onChange={handleChange} onBlur={handleBlur} placeholder='Start Date' />
                                                                </Form.Field>
                                                            </Form.Group>
                                                            <Form.Group widths='equal'>
                                                                <Form.Field>
                                                                    <Form.Input fluid label="End Date" type='date' name='endDate' value={values.endDate} onChange={handleChange} onBlur={handleBlur} placeholder='End Date' />
                                                                </Form.Field>
                                                            </Form.Group>
                                                        </Form>
                                                    </Tab.Pane>
                                                ),
                                            },
                                            {
                                                menuItem: 'LIMIT',
                                                render: () => (
                                                    <Tab.Pane attached={false}>
                                                        <Table celled structured>
                                                            <Table.Header>
                                                                <Table.Row>
                                                                    <Table.HeaderCell rowSpan='2'>Currency</Table.HeaderCell>
                                                                    <Table.HeaderCell colSpan='2'>Transaction</Table.HeaderCell>
                                                                    <Table.HeaderCell colSpan='2'>Daily</Table.HeaderCell>
                                                                    <Table.HeaderCell colSpan='2'>Weekly</Table.HeaderCell>
                                                                    <Table.HeaderCell colSpan='2'>Monthly</Table.HeaderCell>
                                                                    <Table.HeaderCell colSpan='2'>Lifetime</Table.HeaderCell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.HeaderCell>Min Value</Table.HeaderCell>
                                                                    <Table.HeaderCell>Max Value</Table.HeaderCell>
                                                                    <Table.HeaderCell>Max</Table.HeaderCell>
                                                                    <Table.HeaderCell>Count</Table.HeaderCell>
                                                                    <Table.HeaderCell>Max</Table.HeaderCell>
                                                                    <Table.HeaderCell>Count</Table.HeaderCell>
                                                                    <Table.HeaderCell>Max</Table.HeaderCell>
                                                                    <Table.HeaderCell>Count</Table.HeaderCell>
                                                                    <Table.HeaderCell>Max Amount</Table.HeaderCell>
                                                                    <Table.HeaderCell>Max Count</Table.HeaderCell>
                                                                </Table.Row>
                                                            </Table.Header>

                                                            <Table.Body>
                                                                <Table.Row>
                                                                    <Table.Cell>
                                                                        <Form.Field>
                                                                            {
                                                                                this.props.user.includes('permissions.customer.management.isRbksUser') ?
                                                                                    <Select
                                                                                        name="currency"
                                                                                        defaultValue='EUR'
                                                                                        options={[
                                                                                            { key: 'EUR', value: 'EUR', text: 'European EUR' }
                                                                                        ]}
                                                                                        onChange={this.onSelectChange}
                                                                                        style={{ borderRadius: 0, height: 50 }} /> :
                                                                                    <Select
                                                                                        name="currency"
                                                                                        options={[
                                                                                            { key: 'ALL', value: 'ALL', text: 'Albanian ALL' }
                                                                                        ]}
                                                                                        onChange={this.onSelectChange}
                                                                                        style={{ borderRadius: 0, height: 50 }} />
                                                                            }
                                                                        </Form.Field>
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Form.Field>
                                                                            <Form.Input fluid type='number' min="0" name='transactionMin' value={values.transactionMin} onChange={handleChange} onBlur={handleBlur} />
                                                                        </Form.Field>
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Form.Input fluid type='number' min="0" name='transactionMax' value={values.transactionMax} onChange={handleChange} onBlur={handleBlur} />
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Form.Field>
                                                                            <Form.Input fluid type='number' min="0" name='dailyMax' value={values.dailyMax} onChange={handleChange} onBlur={handleBlur} />
                                                                        </Form.Field>
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Form.Input fluid type='number' min="0" name='dailyCount' value={values.dailyCount} onChange={handleChange} onBlur={handleBlur} />
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Form.Field>
                                                                            <Form.Input fluid type='number' min="0" name='weeklyMax' value={values.weeklyMax} onChange={handleChange} onBlur={handleBlur} />
                                                                        </Form.Field>
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Form.Input fluid type='number' min="0" name='weeklyCount' value={values.weeklyCount} onChange={handleChange} onBlur={handleBlur} />
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Form.Field>
                                                                            <Form.Input fluid type='number' min="0" name='monthlyMax' value={values.monthlyMax} onChange={handleChange} onBlur={handleBlur} />
                                                                        </Form.Field>
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Form.Input fluid type='number' min="0" name='monthlyCount' value={values.monthlyCount} onChange={handleChange} onBlur={handleBlur} />
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Form.Field>
                                                                            <Form.Input fluid type='number' min="0" name='lifetimeMaxAmount' value={values.lifetimeMaxAmount} onChange={handleChange} onBlur={handleBlur} />
                                                                        </Form.Field>
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Form.Input fluid type='number' min="0" name='lifetimeMaxCount' value={values.lifetimeMaxCount} onChange={handleChange} onBlur={handleBlur} />
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            </Table.Body>
                                                        </Table>
                                                    </Tab.Pane>
                                                ),
                                            },
                                            {
                                                menuItem: 'SUMMARY',
                                                render: () => <Tab.Pane attached={false}>
                                                    <div className="rules-summary">
                                                        <Grid>
                                                            <Grid.Column computer={5}>
                                                                <div className="rule-summary-header">Condition</div>
                                                                <h4><strong>Business Unit</strong>: {channel === 0 ? 'KUIK' : 'RPLUS'}</h4>
                                                                <h4><strong>Channel Role</strong>: {categoryList.map(category => {
                                                                    if (category.id === customerCategory) {
                                                                        return category.name === 'fullyFledged' ? 'Fully-Fledged' : 'Limited';
                                                                    }
                                                                })}</h4>
                                                            </Grid.Column>
                                                            <Grid.Column computer={5}>
                                                                <div className="rule-summary-header">Limits</div>
                                                                <h4><strong>Currency</strong>: {currency}</h4>
                                                                <h4>{values.transactionMax || values.transactionMin ? <strong>Transaction:</strong> : null} {this.displayNotNullableValues('max', values.transactionMax)} {this.displayNotNullableValues('min', values.transactionMin)}</h4>
                                                                <h4>{values.dailyMax || values.dailyCount ? <strong>Daily:</strong> : null} {this.displayNotNullableValues('max', values.dailyMax)} {this.displayNotNullableValues('count', values.dailyCount)}</h4>
                                                                <h4>{values.weeklyMax || values.weeklyCount ? <strong>Weekly:</strong> : null} {this.displayNotNullableValues('max', values.weeklyMax)} {this.displayNotNullableValues('count', values.weeklyCount)}</h4>
                                                                <h4>{values.monthlyMax || values.monthlyCount ? <strong>Monthly:</strong> : null} {this.displayNotNullableValues('max', values.monthlyMax)} {this.displayNotNullableValues('count', values.monthlyCount)}</h4>
                                                                <h4>{values.lifetimeMaxAmount || values.lifetimeMaxCount ? <strong>Lifetime:</strong> : null} {this.displayNotNullableValues('max', values.lifetimeMaxAmount)} {this.displayNotNullableValues('count', values.lifetimeMaxCount)}</h4>
                                                            </Grid.Column>
                                                            <Grid.Column computer={6}>
                                                                <div className="rule-summary-header">Operation</div>
                                                                <h4><strong>Operation</strong>: {operation === 1 ? 'P2P' : 'Balance Enquiry'}</h4>
                                                                <h4><strong>Start Date</strong>: {values.startDate}</h4>
                                                                <h4><strong>End Date</strong>: {values.endDate}</h4>
                                                            </Grid.Column>
                                                        </Grid>
                                                    </div>
                                                </Tab.Pane>,
                                            }
                                        ]}
                                        activeIndex={activeIndex}
                                        onTabChange={this.handleTabChange}
                                    />
                                </div>
                            </Form>
                        </Dialog>
                    )}
                </Formik>

                <Formik
                    enableReinitialize
                    initialValues={{ priority: priority, startDate: startDate, endDate: endDate, transactionMin: minAmount, transactionMax: maxAmount, dailyMax: maxAmountDaily, dailyCount: maxCountDaily, weeklyMax: maxAmountWeekly, weeklyCount: maxCountWeekly, monthlyMax: maxAmountMonthly, monthlyCount: maxCountMonthly, lifetimeMaxAmount: maxAmountLifetime, lifetimeMaxCount: maxCountLifetime }}
                    onSubmit={(values, { resetForm }) => {
                        this.updateRuleHandler(values);
                        resetForm();
                    }}
                    validationSchema={Yup.object().shape({
                        priority: Yup.number()
                            .required('This field is required.')
                    })}
                >
                    {({ handleChange, handleBlur, errors, touched, values, handleSubmit }) => (
                        <Dialog
                            className="rule-modal"
                            open={isUpdateDialog}
                            size={'fullscreen'}
                            onCancel={() => {
                                this.setState(initialState);
                            }}
                            onConfirm={handleSubmit}
                            title={'Update Rule'}
                            confirmText={'Update Rule'}
                            loading={isLoading}
                        >
                            <Form>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input fluid label="Priority" type='number' name='priority' placeholder='Priority' value={values.priority} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.priority && touched.priority ? (
                                            <span className='formik-error-msg'>{errors.priority}</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                                <div>
                                    <div className="control-buttons">
                                        <button className="control-btn" onClick={this.prevTabHandler}><Icon name='chevron left' /> Previous</button>
                                        <button className="control-btn" onClick={this.nextTabHandler}>Next <Icon name='chevron right' /></button>
                                    </div>
                                    <Tab
                                        menu={{ secondary: true, pointing: true }}
                                        panes={[
                                            {
                                                menuItem: 'CHANNEL',
                                                render: () => (
                                                    <Tab.Pane attached={false}>
                                                        <React.Fragment>
                                                            <Form.Group widths='equal'>
                                                                <Form.Field>
                                                                    <label>Channel</label>
                                                                    {
                                                                        this.props.user.includes('permissions.customer.management.isRbksUser') ?
                                                                            <Select
                                                                                defaultValue={channel === 'RON' ? 'RPLUS' : channel}
                                                                                options={[
                                                                                    { key: 'ron', value: 0, text: 'RPLUS' },
                                                                                ]}
                                                                                onChange={this.onSelectChange}
                                                                                style={{ borderRadius: 0, height: 50 }} /> :
                                                                            <Select
                                                                                defaultValue={channel === 'RON' ? 'RPLUS' : channel}
                                                                                name="channel"
                                                                                options={[
                                                                                    { key: 'kuik', value: 0, text: 'KUIK' },
                                                                                    { key: 'ron', value: 1, text: 'RPLUS' },
                                                                                ]}
                                                                                onChange={this.onSelectChange}
                                                                                style={{ borderRadius: 0, height: 50 }} />
                                                                    }
                                                                </Form.Field>
                                                            </Form.Group>
                                                            <Form.Group widths='equal'>
                                                                <Form.Field>
                                                                    <label>Customer Category</label>
                                                                    <Select
                                                                        defaultValue={customerCategory}
                                                                        name="customerCategory"
                                                                        options={categoryList.map(category => {
                                                                            return { key: category.id, value: category.id, text: category.name === 'fullyFledged' ? 'Fully-Fledged' : 'Limited' }
                                                                        })}
                                                                        onChange={this.onSelectChange}
                                                                        style={{ borderRadius: 0, height: 50 }} />
                                                                </Form.Field>
                                                            </Form.Group>
                                                        </React.Fragment>
                                                    </Tab.Pane>
                                                ),
                                            },
                                            {
                                                menuItem: 'OPERATION',
                                                render: () => (
                                                    <Tab.Pane attached={false}>
                                                        <Form>
                                                            <Form.Group widths='equal'>
                                                                <Form.Field>
                                                                    <label>Transfer Type</label>
                                                                    <Select
                                                                        defaultValue={operation}
                                                                        name="operation"
                                                                        options={[
                                                                            { key: 'p2p', value: 1, text: 'P2P Transfer' },
                                                                            { key: 'balance', value: 4, text: 'Balance Enquiry' },
                                                                        ]}
                                                                        onChange={this.onSelectChange}
                                                                        style={{ borderRadius: 0, height: 50 }} />
                                                                </Form.Field>
                                                            </Form.Group>
                                                            <Form.Group widths='equal'>
                                                                <Form.Field>
                                                                    <Form.Input fluid label="Start Date" type='date' name='startDate' value={values.startDate != null && isUpdateDialog ? values.startDate.split('T')[0] : values.startDate} onChange={handleChange} onBlur={handleBlur} placeholder='Start Date' />
                                                                </Form.Field>
                                                            </Form.Group>
                                                            <Form.Group widths='equal'>
                                                                <Form.Field>
                                                                    <Form.Input fluid label="End Date" type='date' name='endDate' value={values.endDate != null && isUpdateDialog ? values.endDate.split('T')[0] : values.endDate} onChange={handleChange} onBlur={handleBlur} placeholder='End Date' />
                                                                </Form.Field>
                                                            </Form.Group>
                                                        </Form>
                                                    </Tab.Pane>
                                                ),
                                            },
                                            {
                                                menuItem: 'LIMIT',
                                                render: () => (
                                                    <Tab.Pane attached={false}>
                                                        <Table celled structured>
                                                            <Table.Header>
                                                                <Table.Row>
                                                                    <Table.HeaderCell rowSpan='2'>Currency</Table.HeaderCell>
                                                                    <Table.HeaderCell colSpan='2'>Transaction</Table.HeaderCell>
                                                                    <Table.HeaderCell colSpan='2'>Daily</Table.HeaderCell>
                                                                    <Table.HeaderCell colSpan='2'>Weekly</Table.HeaderCell>
                                                                    <Table.HeaderCell colSpan='2'>Monthly</Table.HeaderCell>
                                                                    <Table.HeaderCell colSpan='2'>Lifetime</Table.HeaderCell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.HeaderCell>Min Value</Table.HeaderCell>
                                                                    <Table.HeaderCell>Max Value</Table.HeaderCell>
                                                                    <Table.HeaderCell>Max</Table.HeaderCell>
                                                                    <Table.HeaderCell>Count</Table.HeaderCell>
                                                                    <Table.HeaderCell>Max</Table.HeaderCell>
                                                                    <Table.HeaderCell>Count</Table.HeaderCell>
                                                                    <Table.HeaderCell>Max</Table.HeaderCell>
                                                                    <Table.HeaderCell>Count</Table.HeaderCell>
                                                                    <Table.HeaderCell>Max Amount</Table.HeaderCell>
                                                                    <Table.HeaderCell>Max Count</Table.HeaderCell>
                                                                </Table.Row>
                                                            </Table.Header>

                                                            <Table.Body>
                                                                <Table.Row>
                                                                    <Table.Cell>
                                                                        <Form.Field>
                                                                            {
                                                                                this.props.user.includes('permissions.customer.management.isRbksUser') ?
                                                                                    <Select
                                                                                        name="currency"
                                                                                        defaultValue='EUR'
                                                                                        options={[
                                                                                            { key: 'EUR', value: 'EUR', text: 'European EUR' }
                                                                                        ]}
                                                                                        onChange={this.onSelectChange}
                                                                                        style={{ borderRadius: 0, height: 50 }} /> :
                                                                                    <Select
                                                                                        name="currency"
                                                                                        options={[
                                                                                            { key: 'ALL', value: 'ALL', text: 'Albanian ALL' }
                                                                                        ]}
                                                                                        onChange={this.onSelectChange}
                                                                                        style={{ borderRadius: 0, height: 50 }} />
                                                                            }
                                                                        </Form.Field>
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Form.Field>
                                                                            <Form.Input fluid type='number' min="0" name='transactionMin' value={values.transactionMin} onChange={handleChange} onBlur={handleBlur} />
                                                                        </Form.Field>
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Form.Input fluid type='number' min="0" name='transactionMax' value={values.transactionMax} onChange={handleChange} onBlur={handleBlur} />
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Form.Field>
                                                                            <Form.Input fluid type='number' min="0" name='dailyMax' value={values.dailyMax} onChange={handleChange} onBlur={handleBlur} />
                                                                        </Form.Field>
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Form.Input fluid type='number' min="0" name='dailyCount' value={values.dailyCount} onChange={handleChange} onBlur={handleBlur} />
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Form.Field>
                                                                            <Form.Input fluid type='number' min="0" name='weeklyMax' value={values.weeklyMax} onChange={handleChange} onBlur={handleBlur} />
                                                                        </Form.Field>
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Form.Input fluid type='number' min="0" name='weeklyCount' value={values.weeklyCount} onChange={handleChange} onBlur={handleBlur} />
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Form.Field>
                                                                            <Form.Input fluid type='number' min="0" name='monthlyMax' value={values.monthlyMax} onChange={handleChange} onBlur={handleBlur} />
                                                                        </Form.Field>
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Form.Input fluid type='number' min="0" name='monthlyCount' value={values.monthlyCount} onChange={handleChange} onBlur={handleBlur} />
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Form.Field>
                                                                            <Form.Input fluid type='number' min="0" name='lifetimeMaxAmount' value={values.lifetimeMaxAmount} onChange={handleChange} onBlur={handleBlur} />
                                                                        </Form.Field>
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Form.Input fluid type='number' min="0" name='lifetimeMaxCount' value={values.lifetimeMaxCount} onChange={handleChange} onBlur={handleBlur} />
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            </Table.Body>
                                                        </Table>
                                                    </Tab.Pane>
                                                ),
                                            },
                                            {
                                                menuItem: 'SUMMARY',
                                                render: () => <Tab.Pane attached={false}>
                                                    <div className="rules-summary">
                                                        <Grid>
                                                            <Grid.Column computer={5}>
                                                                <div className="rule-summary-header">Condition</div>
                                                                <h4><strong>Business Unit</strong>: {channel === 0 ? 'KUIK' : 'RPLUS'}</h4>
                                                                <h4><strong>Channel Role</strong>: {categoryList.map(category => {
                                                                    if (category.id === customerCategory) {
                                                                        return category.name === 'fullyFledged' ? 'Fully-Fledged' : 'Limited';
                                                                    }
                                                                })}</h4>
                                                            </Grid.Column>
                                                            <Grid.Column computer={5}>
                                                                <div className="rule-summary-header">Limits</div>
                                                                <h4><strong>Currency</strong>: {currency}</h4>
                                                                <h4>{values.transactionMax || values.transactionMin ? <strong>Transaction:</strong> : null} {this.displayNotNullableValues('max', values.transactionMax)} {this.displayNotNullableValues('min', values.transactionMin)}</h4>
                                                                <h4>{values.dailyMax || values.dailyCount ? <strong>Daily:</strong> : null} {this.displayNotNullableValues('max', values.dailyMax)} {this.displayNotNullableValues('count', values.dailyCount)}</h4>
                                                                <h4>{values.weeklyMax || values.weeklyCount ? <strong>Weekly:</strong> : null} {this.displayNotNullableValues('max', values.weeklyMax)} {this.displayNotNullableValues('count', values.weeklyCount)}</h4>
                                                                <h4>{values.monthlyMax || values.monthlyCount ? <strong>Monthly:</strong> : null} {this.displayNotNullableValues('max', values.monthlyMax)} {this.displayNotNullableValues('count', values.monthlyCount)}</h4>
                                                                <h4>{values.lifetimeMaxAmount || values.lifetimeMaxCount ? <strong>Lifetime:</strong> : null} {this.displayNotNullableValues('max', values.lifetimeMaxAmount)} {this.displayNotNullableValues('count', values.lifetimeMaxCount)}</h4>
                                                            </Grid.Column>
                                                            <Grid.Column computer={6}>
                                                                <div className="rule-summary-header">Operation</div>
                                                                <h4><strong>Operation</strong>: {operation === 1 ? 'P2P' : 'Balance Enquiry'}</h4>
                                                                <h4><strong>Start Date</strong>: {values.startDate}</h4>
                                                                <h4><strong>End Date</strong>: {values.endDate}</h4>
                                                            </Grid.Column>
                                                        </Grid>
                                                    </div>
                                                </Tab.Pane>,
                                            }
                                        ]}
                                        activeIndex={activeIndex}
                                        onTabChange={this.handleTabChange}
                                    />
                                </div>
                            </Form>
                        </Dialog>
                    )}
                </Formik>

                <Dialog
                    open={isDeleteDialog}
                    onCancel={() => {
                        this.setState(initialState);
                    }}
                    onConfirm={this.deleteRuleHandler}
                    title={'Delete Rule'}
                    confirmText={'Delete'}
                    loading={isLoading}
                ><p>Are you sure you want to delete this rule?</p></Dialog>

                <ToastContainer />
            </Fragment>
        );
    }
};

const mapStateToProps = state => ({
    user: state.auth.user['claim.permission']
})

export default connect(mapStateToProps, null)(Rules);