import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Button } from 'semantic-ui-react';
import HavePermission from './PermissionsService/HavePermission';
import HavePermissionsGroup from './PermissionsService/HavePermissionsGroup';
import Logo from '../assets/base-kuik-logo.png';

class Navbar extends Component {
    logOutHandler = () => {
        localStorage.removeItem('token');
        window.location.href = '/login';
    }

    render() {
        return (
            <header className="header-6">
                <div className="branding">
                    <a href="#" className="nav-link">
                        <img src={Logo} alt='Logo' />
                    </a>
                </div>
                <div className="header-nav">
                    <Link to={'/dashboard'} className="nav-link nav-text" onClick={this.props.addTab}>Dashboard</Link>
                    <HavePermission for='manage.users.list'>
                        <Link to='/users' className="nav-link nav-text" onClick={this.props.addTab}>Users</Link>
                    </HavePermission>
                    <HavePermissionsGroup permissionsGroup={[
                        'permissions.manage.roles.list',
                        'permissions.manage.permission.list',
                    ]}>
                        <Dropdown text="Access" className="nav-link nav-text">
                            <Dropdown.Menu>
                                <HavePermission for="manage.roles.list">
                                    <Link to='/roles'><Dropdown.Item text='Roles' onClick={this.props.addTab} /></Link>
                                </HavePermission>
                                <HavePermission for="manage.permission.list">
                                    <Link to='/permissions'><Dropdown.Item text='Permissions' onClick={this.props.addTab} /></Link>
                                </HavePermission>
                            </Dropdown.Menu>
                        </Dropdown>
                    </HavePermissionsGroup>
                    <HavePermissionsGroup permissionsGroup={[
                        'permissions.manage.mno.list',
                        'permissions.error.management.view.error.list',
                    ]}>
                        <Dropdown text="System" className="nav-link nav-text">
                            <Dropdown.Menu>
                                <HavePermission for="manage.mno.list">
                                    <Link to='/mno-management'><Dropdown.Item text='MNO Management' onClick={this.props.addTab} /></Link>
                                </HavePermission>
                                <HavePermission for="error.management.view.error.list">
                                    <Link to='/error-management'><Dropdown.Item text='Error Management' onClick={this.props.addTab} /></Link>
                                </HavePermission>
                            </Dropdown.Menu>
                        </Dropdown>
                    </HavePermissionsGroup>
                    <HavePermission for='manage.banks.list'>
                        <Link to='/bank-management' className="nav-link nav-text" onClick={this.props.addTab}>Bank Management</Link>
                    </HavePermission>
                    <HavePermission for='manage.customer.category.list'>
                        <Link to='/customer-category' className="nav-link nav-text" onClick={this.props.addTab}>Customer Category</Link>
                    </HavePermission>
                    <HavePermissionsGroup permissionsGroup={[
                        'permissions.manage.rules.list'
                    ]}>
                        <Dropdown text="FCL" className="nav-link nav-text">
                            <Dropdown.Menu>
                                <HavePermission for="manage.rules.list">
                                    <Link to='/rules'><Dropdown.Item text='Rules' onClick={this.props.addTab} /></Link>
                                </HavePermission>
                            </Dropdown.Menu>
                        </Dropdown>
                    </HavePermissionsGroup>
                    <HavePermissionsGroup permissionsGroup={[
                        'permissions.system.alert.list',
                        'permissions.view.message.history'
                    ]}>
                        <Dropdown text="Alert &amp; Notifications" className="nav-link nav-text">
                            <Dropdown.Menu>
                                <HavePermission for="system.alert.list">
                                    <Link to='/system-alerts'><Dropdown.Item text='System Alerts' onClick={this.props.addTab} /></Link>
                                </HavePermission>
                                <HavePermission for="view.message.history">
                                    <Link to='/message-history'><Dropdown.Item text='Message History' onClick={this.props.addTab} /></Link>
                                </HavePermission>
                            </Dropdown.Menu>
                        </Dropdown>
                    </HavePermissionsGroup>
                </div>
                <div className="header-actions">
                    <Dropdown icon='user circle' className="nav-link nav-icon" direction='left'>
                        <Dropdown.Menu direction='left' className='user-dropdown'>
                            <div>{this.props.user['claim.identifier']}</div>
                            <Button size='mini' primary onClick={this.logOutHandler}>Log out</Button>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </header>
        );
    }
}

export default Navbar;