import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import PrivateRoute from "./components/PrivateRoute";
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import store from './store';
import Login from './layout/Login';
import Layout from './layout/Layout';
import { setCurrentUser } from "./store/actions/auth";
import jwt_decode from 'jwt-decode';
import './assets/css/theme.css';

if (localStorage.token) {
  const decoded = jwt_decode(localStorage.token);
  store.dispatch(setCurrentUser(decoded));
}

export class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <UnauthenticatedRoute path="/login" exact component={Login}></UnauthenticatedRoute>
            <PrivateRoute path="/" component={Layout}></PrivateRoute>
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}

