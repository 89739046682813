import React, { Component, Fragment } from 'react';
import List from "../components/Table/List";
import HeaderContent from "../components/HeaderContent";
import { Divider, Accordion, Icon } from "semantic-ui-react";

class Permissions extends Component {
    state = {
        activeIndex: 0,
        data: []
    };

    getPermissionDataHandler = (value) => {
        this.setState({ data: value });
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    render() {
        const { activeIndex, data } = this.state;

        return (
            <Fragment>
                <HeaderContent />
                <Divider />
                <List setRefresh={refresh => this.refreshChild = refresh} getPermissionData={this.getPermissionDataHandler} />

                <Accordion styled className="permissions-accord">
                    {data.map(item => {
                        return (
                            <React.Fragment>
                                <Accordion.Title
                                    active={activeIndex === 0}
                                    index={0}
                                    onClick={this.handleClick}
                                >
                                    <Icon name='dropdown' />
                                    {item.groupName}
                                </Accordion.Title>
                                {item.permissions.map(permission => {
                                    return (
                                        <Accordion.Content active={activeIndex === 0}>
                                            <p>
                                                {permission}
                                            </p>
                                        </Accordion.Content>
                                    );
                                })}
                            </React.Fragment>
                        );
                    })}
                </Accordion>
            </Fragment>
        );
    }
};

export default Permissions;