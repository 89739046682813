import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';

class Alert extends Component {
    render() {
        return (
            <div className="alert alert-app-level alert-info" role="alert">
                <div className="alert-items">
                    <div className="alert-item static">
                        <div className="alert-icon-wrapper">
                            <Icon name='info circle' size='large' />
                        </div>
                        <div>
                            Alert Type: Info.
                        </div>
                    </div>
                </div>
                <Icon name='close' size='large' className="close" aria-label="Close" style={closeIconStyle} />
            </div>
        );
    }
}

const closeIconStyle = {
    paddingTop: '8px',
    cursor: 'pointer'
};

export default Alert;