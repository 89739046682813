import React, { Component, Fragment } from 'react';
import config from '../config';
import List from "../components/Table/List";
import HeaderContent from "../components/HeaderContent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Divider, Form } from "semantic-ui-react";
import Dialog from '../components/UI/Dialog';
import { Formik } from 'formik';
import * as Yup from 'yup';

class BankBranches extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            isUpdateDialog: false,
            isDeleteDialog: false,
            loading: false,
            id: '',
            name: '',
            code: '',
            bankId: ''
        }

        this.callbackFunction = this.callbackFunction.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.createBranchHandler = this.createBranchHandler.bind(this);
        this.createNewBranchHandler = this.createNewBranchHandler.bind(this);
        this.updateBranchHandler = this.updateBranchHandler.bind(this);
        this.deleteBranchHandler = this.deleteBranchHandler.bind(this);
    };

    callbackFunction(childData, identifier) {
        this.setState({ id: childData.id });
        this.setState({ name: childData.name });
        this.setState({ code: childData.code });
        this.setState({ bankId: childData.bankId });

        if (identifier === 'update') {
            this.setState({ isUpdateDialog: true });
        } else if (identifier === 'delete') {
            this.setState({ isDeleteDialog: true });
        };
    };

    onInputChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    };

    createBranchHandler() {
        this.setState({ open: true });
    };

    createNewBranchHandler(values) {
        const { name, code, bankId } = values;

        this.setState({ loading: true });
        fetch(`${config.adminBaseURL}/bankbranch/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "code": parseInt(code),
                "name": name,
                "bankId": bankId
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ loading: false });
            if (data.success) {
                this.setState({ open: false });
                this.setState({ name: '' });
                this.setState({ code: '' });
                this.setState({ bankId: '' });
                toast.success('Bank Branch created successfully!');
                this.refreshChild();
            } else {
                toast.error(data.description);
            }
        })
    };


    updateBranchHandler() {
        const { name, code, bankId, id } = this.state;

        this.setState({ loading: true });
        fetch(`${config.adminBaseURL}/bankbranch/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "id": id,
                "code": parseInt(code),
                "name": name,
                "bankId": bankId
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ loading: false });
            if (data.success) {
                this.setState({ isUpdateDialog: false });
                this.setState({ id: '' });
                this.setState({ name: '' });
                this.setState({ code: '' });
                this.setState({ bankId: '' });
                toast.success('Bank Branch updated successfully!');
                this.refreshChild();
            } else {
                toast.error(data.description);
            }
        })
    };


    deleteBranchHandler() {
        this.setState({ loading: true });
        fetch(`${config.adminBaseURL}/bankbranch/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "id": this.state.id
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ loading: false });
            if (data.success) {
                this.setState({ isDeleteDialog: false });
                this.setState({ id: '' });
                this.setState({ name: '' });
                this.setState({ code: '' });
                this.setState({ bankId: '' });
                toast.success('Bank Branch deleted successfully!');
                this.refreshChild();
            } else {
                toast.error(data.description);
            }
        })
    };

    render() {
        const { name, code, bankId, loading, open, isUpdateDialog, isDeleteDialog } = this.state;

        return (
            <Fragment>
                <HeaderContent onClickHandler={this.createBranchHandler} buttonTitle={'Create New Bank Branch'} showButton={true} />
                <Divider />
                <List setRefresh={refresh => this.refreshChild = refresh} parentCallback={this.callbackFunction} />

                <Formik
                    initialValues={{ name: name, code: code, bankId: bankId }}
                    onSubmit={(values, { resetForm }) => {
                        this.createNewBranchHandler(values);
                        resetForm();
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .required('This field is required.'),
                        code: Yup.string()
                            .required('This field is required.'),
                        bankId: Yup.string()
                            .required('This field is required.'),
                    })}
                >
                    {({ handleChange, handleBlur, errors, touched, values, handleSubmit }) => (
                        <Dialog
                            open={open}
                            onCancel={() => {
                                this.setState({ open: false })
                            }}
                            onConfirm={handleSubmit}
                            title={'Create New Bank Branch'}
                            confirmText={'Create Branch'}
                            loading={loading}
                        >
                            <Form>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input fluid label="Name" type='text' name='name' placeholder='Name' value={values.name} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.name && touched.name ? (
                                            <span className='formik-error-msg'>{errors.name}</span>
                                        ) : null}
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input fluid label="Code" type='text' name='code' placeholder='Code' value={values.code} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.code && touched.code ? (
                                            <span className='formik-error-msg'>{errors.code}</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input fluid label="Bank Id" type='text' name='bankId' placeholder='Bank Id' value={values.bankId} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.bankId && touched.bankId ? (
                                            <span className='formik-error-msg'>{errors.bankId}</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </Dialog>
                    )}
                </Formik>


                <Dialog
                    open={isUpdateDialog}
                    onCancel={() => {
                        this.setState({ isUpdateDialog: false });
                        this.setState({ id: '' });
                        this.setState({ name: '' });
                        this.setState({ code: '' });
                        this.setState({ bankId: '' });
                    }}
                    onConfirm={this.updateBranchHandler}
                    title={'Update Branch'}
                    confirmText={'Update Branch'}
                    loading={loading}
                >
                    <Form>
                        <Form.Group widths='equal'>
                            <Form.Input fluid label="Name" type='text' name='name' placeholder='Name' value={name} onChange={this.onInputChange} />
                            <Form.Input fluid label="Code" type='text' name='code' placeholder='Code' value={code} onChange={this.onInputChange} />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Input fluid label="Bank Id" type='text' name='bankId' placeholder='Bank Id' value={bankId} onChange={this.onInputChange} />
                        </Form.Group>
                    </Form>
                </Dialog>

                <Dialog
                    open={isDeleteDialog}
                    onCancel={() => {
                        this.setState({ isDeleteDialog: false });
                        this.setState({ id: '' });
                        this.setState({ name: '' });
                        this.setState({ code: '' });
                        this.setState({ bankId: '' });
                    }}
                    onConfirm={this.deleteBranchHandler}
                    title={'Delete Branch'}
                    confirmText={'Delete Branch'}
                    loading={loading}
                ><p>Are you sure you want to delete this branch?</p></Dialog>

                <ToastContainer />
            </Fragment>
        );
    }
};

export default BankBranches;