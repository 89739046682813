export var filterInputsList = [
    {
        pathname: '/users',
        filters: [
            {
                label: 'Status',
                model: 'select',
                options: [
                    {
                        key: 0,
                        value: 'new',
                        text: 'New',
                        isClicked: false
                    },
                    {
                        key: 1,
                        value: 'pending',
                        text: 'Pending',
                        isClicked: false
                    },
                    {
                        key: 2,
                        value: 'approved',
                        text: 'Approved',
                        isClicked: false
                    },
                    {
                        key: 3,
                        value: 'rejected',
                        text: 'Rejected',
                        isClicked: false
                    }
                ]
            },
            {
                label: 'Lock Status',
                model: 'select',
                options: [
                    {
                        key: 0,
                        value: 'unlocked',
                        text: 'Unlocked',
                        isClicked: false
                    },
                    {
                        key: 1,
                        value: 'locked',
                        text: 'Locked',
                        isClicked: false
                    }
                ]
            },
            {
                label: "Username",
                type: "text",
                value: ''
            }
        ]
    },
    {
        pathname: '/system-alerts',
        filters: [
            {
                label: 'Delivery Channel(s)',
                model: 'select',
                options: [
                    {
                        key: 0,
                        value: 'all',
                        text: 'All',
                        isClicked: false
                    },
                    {
                        key: 1,
                        value: 'sms',
                        text: 'Sms',
                        isClicked: false
                    },
                    {
                        key: 2,
                        value: 'push',
                        text: 'Push',
                        isClicked: false
                    },
                    {
                        key: 3,
                        value: 'email',
                        text: 'Email',
                        isClicked: false
                    }
                ]
            },
            {
                label: 'Status',
                model: 'select',
                options: [
                    {
                        key: 0,
                        value: 'inactive',
                        text: 'Inactive',
                        isClicked: false
                    },
                    {
                        key: 1,
                        value: 'active',
                        text: 'Active',
                        isClicked: false
                    }
                ]
            },
            {
                label: "Event Type",
                type: "text",
                value: ''
            }
        ]
    },
    {
        pathname: '/permissions',
        filters: [
            {
                label: "Event Type",
                type: "text",
                value: ''
            }
        ]
    },
    {
        pathname: '/bank-management',
        filters: []
    },
    {
        pathname: '/roles',
        filters: []
    },
    {
        pathname: '/mno-management',
        filters: []
    },
    {
        pathname: '/customer-category',
        filters: []
    },
    {
        pathname: '/error-management',
        filters: []
    },
    {
        pathname: '/rules',
        filters: []
    },
    {
        pathname: '/message-history',
        filters: []
    },
    {
        pathname: '/user-details',
        filters: []
    }
]
