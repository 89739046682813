import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Grid } from 'semantic-ui-react';
import { useLocation } from "react-router-dom";
import config from '../config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HavePermission from '../components/PermissionsService/HavePermission';

const UserDetails = props => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);

    const location = useLocation();

    useEffect(() => {
        setUser(location.state.details);
    }, [location]);

    const approveHandler = () => {
        setIsLoading(true);
        fetch(`${config.adminBaseURL}/user/approve`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "userId": user.personId
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            toast.success('User approved successfully!');
            setTimeout(() => {
                props.history.push('/users');
            }, 1000);
            setIsLoading(false);
        })
    }

    const rejectHandler = () => {
        setIsLoading2(true);
        fetch(`${config.adminBaseURL}/user/reject`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "userId": user.personId
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            toast.success('User rejected successfully!');
            setTimeout(() => {
                props.history.push('/users');
            }, 1000);
            setIsLoading2(false);
        });
    }

    const cancelHandler = () => {
        props.history.goBack();
    };

    const isDataChanged = (data) => {
        return data !== null ? { background: '#FEEFF2' } : null;
    };

    const isNewDataChanged = (data) => {
        return data !== null ? { background: '#E8F2FF' } : null;
    };

    const checkRoles = () => {
        if (user.roles.sort().join(',') !== user.newRoles.sort().join(',')) {
            return true;
        }
    }

    const areRolesChanged = () => {
        const val = checkRoles();
        return val ? { background: '#FEEFF2' } : {};
    }

    const areNewRolesChanged = () => {
        const val = checkRoles();
        return val ? { background: '#E8F2FF' } : {};
    }

    return (
        <React.Fragment>
            {user ? (
                <React.Fragment>
                    <Grid>
                        <Grid.Column tablet={16} computer={6}>
                            <h2>Validate User: {user.firstName} {user.lastName}</h2>
                        </Grid.Column>
                        <Grid.Column tablet={16} computer={10} className="buttons-group-col">
                            <HavePermission for="manage.users.authorize.changes">
                                <Button loading={isLoading} primary onClick={approveHandler}>Approve</Button>
                            </HavePermission>
                            <Button loading={isLoading2} primary onClick={rejectHandler}>Reject</Button>
                            <Button primary onClick={cancelHandler}>Cancel</Button>
                        </Grid.Column>
                    </Grid>
                    <br />
                    <Grid>
                        <Grid.Column computer={8}>
                            <div className="info">
                                <div className="info-header"><h4>Information</h4></div>
                                <div className="info-body">
                                    <div className="info-item">
                                        <div computer={8} className="first-col">
                                            <p style={isDataChanged(user.newFirstName)}><strong>First Name</strong></p>
                                            <p style={isDataChanged(user.newLastName)}><strong>Last Name</strong></p>
                                            <p style={isDataChanged(user.newUsername)}><strong>Username</strong></p>
                                            <p style={isDataChanged(user.newPhone)}><strong>Phone</strong></p>
                                            <p style={isDataChanged(user.newEmail)}><strong>Email</strong></p>
                                            <p style={isDataChanged(user.newAddress)}><strong>Address</strong></p>
                                            <p style={isDataChanged(user.newGender)}><strong>Gender</strong></p>
                                            <p style={isDataChanged(user.newBirthDate)}><strong>BirthDate</strong></p>
                                            <p style={areRolesChanged()}><strong>Roles</strong></p>
                                        </div>
                                        <div computer={8} className="second-col">
                                            <p style={isDataChanged(user.newFirstName)}>{user.firstName}</p>
                                            <p style={isDataChanged(user.newLastName)}>{user.lastName}</p>
                                            <p style={isDataChanged(user.newUsername)}>{user.username}</p>
                                            <p style={isDataChanged(user.newPhone)}>{user.phone}</p>
                                            <p style={isDataChanged(user.newEmail)}>{user.email}</p>
                                            <p style={isDataChanged(user.newAddress)}>{user.address}</p>
                                            <p style={isDataChanged(user.newGender)}>{user.gender === 0 ? 'Male' : 'Female'}</p>
                                            <p style={isDataChanged(user.newBirthDate)}>{user.birthDate}</p>
                                            <p style={Object.assign(areRolesChanged(), { flexWrap: 'wrap' })}>{user.roles.map((role, index) => {
                                                return <span>{index !== user.roles.length - 1 ? role.name + ', ' : role.name}</span>
                                            })}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid.Column>
                        <Grid.Column computer={8}>
                            <div className="info">
                                <div className="info-header"><h4>Updated Information</h4></div>
                                <div className="info-body">
                                    <div className="info-item">
                                        <div computer={8} className="first-col">
                                            <p style={isNewDataChanged(user.newFirstName)}><strong>First Name</strong></p>
                                            <p style={isNewDataChanged(user.newLastName)}><strong>Last Name</strong></p>
                                            <p style={isNewDataChanged(user.newUsername)}><strong>Username</strong></p>
                                            <p style={isNewDataChanged(user.newPhone)}><strong>Phone</strong></p>
                                            <p style={isNewDataChanged(user.newEmail)}><strong>Email</strong></p>
                                            <p style={isNewDataChanged(user.newAddress)}><strong>Address</strong></p>
                                            <p style={isNewDataChanged(user.newGender)}><strong>Gender</strong></p>
                                            <p style={isNewDataChanged(user.newBirthDate)}><strong>BirthDate</strong></p>
                                            <p style={areNewRolesChanged()}><strong>Roles</strong></p>
                                        </div>
                                        <div computer={8} className="second-col">
                                            <p style={isNewDataChanged(user.newFirstName)}>{user.newFirstName === null ? user.firstName : user.newFirstName}</p>
                                            <p style={isNewDataChanged(user.newLastName)}>{user.newLastName === null ? user.lastName : user.newLastName}</p>
                                            <p style={isNewDataChanged(user.newUsername)}>{user.newUsername === null ? user.username : user.newUsername}</p>
                                            <p style={isNewDataChanged(user.newPhone)}>{user.newPhone === null ? user.phone : user.newPhone}</p>
                                            <p style={isNewDataChanged(user.newEmail)}>{user.newEmail === null ? user.email : user.newEmail}</p>
                                            <p style={isNewDataChanged(user.newAddress)}>{user.newAddress === null ? user.address : user.newAddress}</p>
                                            <p style={isNewDataChanged(user.newGender)}>{user.newGender === null ? (user.gender === 0 ? 'Male' : 'Female') : (user.newGender === 0 ? 'Male' : 'Female')}</p>
                                            <p style={isNewDataChanged(user.newBirthDate)}>{user.newBirthDate === null ? user.birthDate : user.newBirthDate}</p>
                                            <p style={Object.assign(areNewRolesChanged(), { flexWrap: 'wrap' })}>{user.newRoles.length === 0 ? user.roles.map((role, index) => {
                                                return <span>{index !== user.roles.length - 1 ? role.name + ', ' : role.name}</span>
                                            }) : user.newRoles.map((role, index) => {
                                                return <span>{index !== user.newRoles.length - 1 ? role.name + ', ' : role.name}</span>
                                            })}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid.Column>
                    </Grid>
                </React.Fragment>
            ) : null}

            <ToastContainer />
        </React.Fragment>
    );
};

export default withRouter(UserDetails);