import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import config from '../config.json';
import List from "../components/Table/List";
import HeaderContent from "../components/HeaderContent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Divider, Form, Checkbox, Select } from "semantic-ui-react";
import Dialog from '../components/UI/Dialog';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Spinner from '../components/UI/Spinner';
import moment from 'moment';
import HavePermission from '../components/PermissionsService/HavePermission';
import { displayErrors } from '../helper';

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            isUpdateDialog: false,
            isDeleteDialog: false,
            isLockDialog: false,
            clearAttemptsDialog: false,
            isUnlockDialog: false,
            loading: false,
            id: '',
            firstName: '',
            lastName: '',
            password: '',
            birthDate: '',
            gender: 'Select Gender',
            address: '',
            email: '',
            phone: '',
            username: '',
            roles: [],
            roleIds: [],
            userRoles: []
        }

        this.callbackFunction = this.callbackFunction.bind(this);
        this.createUserHandler = this.createUserHandler.bind(this);
        this.createNewUserHandler = this.createNewUserHandler.bind(this);
        this.updateUserHandler = this.updateUserHandler.bind(this);
        this.deleteUserHandler = this.deleteUserHandler.bind(this);
    };

    callbackFunction(childData, identifier) {
        this.setState({ id: childData.id });
        this.setState({ firstName: childData.firstName });
        this.setState({ lastName: childData.lastName });
        this.setState({ birthDate: childData.birthDate });
        this.setState({ gender: childData.gender === "Male" ? 0 : 1 });
        this.setState({ address: childData.address });
        this.setState({ email: childData.email });
        this.setState({ phone: childData.phone });
        this.setState({ username: childData.username });
        this.setState({ userRoles: childData.roles });

        if (identifier === 'update') {
            this.setState({ isUpdateDialog: true });
            this.getRoleList();
        } else if (identifier === 'delete') {
            this.setState({ isDeleteDialog: true });
        } else if (identifier === "lock") {
            this.setState({ isLockDialog: true });
        } else if (identifier === 'clearAttempts') {
            this.setState({ clearAttemptsDialog: true });
        } else if (identifier === 'unlock') {
            this.setState({ isUnlockDialog: true })
        } else if (identifier === 'viewDetails') {
            this.viewDetailsHandler(childData.id);
        }
    };

    createUserHandler() {
        this.setState({ open: true });
    };

    createNewUserHandler(values, resetForm) {
        const { firstName, lastName, password, birthDate, confirmPassword, address, email, phone, username } = values;

        this.setState({ loading: true });
        fetch(`${config.adminBaseURL}/user/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "firstName": firstName,
                "lastName": lastName,
                "password": password,
                "confirmPassword": confirmPassword,
                "birthDate": birthDate,
                "gender": this.state.gender,
                "bankId": null,
                "bankBranchId": null,
                "address": address,
                "phone": phone,
                "email": email,
                "roleIds": this.state.roleIds,
                "username": username
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ loading: false });
            if (data.success) {
                this.setState({ open: false });
                this.resetState();
                toast.success('User created successfully!');
                resetForm();
                this.refreshChild();
            } else {
                displayErrors(data, toast);
            }
        })
    };


    updateUserHandler(values) {
        const { firstName, lastName, password, birthDate, gender, address, email, phone, username } = values;
        const { id } = this.state;

        this.setState({ loading: true });
        fetch(`${config.adminBaseURL}/user/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "id": id,
                "firstName": firstName,
                "lastName": lastName,
                "password": password,
                "birthDate": birthDate,
                "gender": this.state.gender,
                "bankId": null,
                "bankBranchId": null,
                "address": address,
                "phone": phone,
                "email": email,
                "roleIds": this.state.roleIds,
                "username": username
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ loading: false });
            if (data.success) {
                this.setState({ isUpdateDialog: false });
                this.resetState();
                toast.success('User updated successfully!');
                this.refreshChild();
            } else {
                toast.error(data.description);
            }
        })
    };


    deleteUserHandler() {
        this.setState({ loading: true });
        fetch(`${config.adminBaseURL}/user/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "id": this.state.id
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ loading: false, isDeleteDialog: false });
            toast.success('User deleted successfully!');
            this.refreshChild();
        }).catch(err => {
            this.setState({ loading: false });
        })
    };

    viewDetailsHandler = (id) => {
        this.setState({ loading: true });
        fetch(`${config.adminBaseURL}/user/details`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "personId": id
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ loading: false });
            this.props.history.push({
                pathname: '/user-details',
                state: { details: data.value }
            });
        }).catch(err => {
            this.setState({ loading: false });
        })
    };

    lockUserHandler = () => {
        this.setState({ loading: true });
        fetch(`${config.adminBaseURL}/user/lock`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "userId": this.state.id
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ loading: false, isLockDialog: false });
            toast.success('User locked successfully!');
            this.refreshChild();
        }).catch(err => {
            this.setState({ loading: false });
            toast.error('Something went wrong!');
        })
    }

    clearAttemptsHandler = () => {
        this.setState({ loading: true });
        fetch(`${config.adminBaseURL}/user/clearattempts`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "userId": this.state.id
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ loading: false, clearAttemptsDialog: false });
            toast.success('Cleared Attempts successfully!');
        }).catch(err => {
            this.setState({ loading: false });
            toast.error('Something went wrong!');
        })
    };

    unlockUserHandler = () => {
        this.setState({ loading: true });
        fetch(`${config.adminBaseURL}/user/unlock`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "userId": this.state.id
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ loading: false, isUnlockDialog: false });
            toast.success('User unlocked successfully!');
            this.refreshChild();
        }).catch(err => {
            this.setState({ loading: false });
            toast.error('Something went wrong!');
        })
    }

    getRoleList = () => {
        fetch(`${config.adminBaseURL}/role/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "name": null,
                "pageIndex": 0,
                "pageSize": 10
            })
        }).then(response => {
            if (response.ok) {
                response.json().then(data => {
                    var roles = data.value.data.filter(ar => !this.state.userRoles.find(rm => (rm === ar.name)));
                    this.setState({ roles });

                    var userRoles = data.value.data.filter(ar => this.state.userRoles.find(rm => (rm === ar.name)));
                    this.setState({ userRoles });
                    userRoles.map(el => {
                        this.checkboxRoleHandler(el.id);
                    })
                });
            } else {
                response.json().then(error => {
                    console.log(`Failed to load data: ${error.message}`);
                });
            }
        });
    }

    checkboxRoleHandler = (roleId) => {
        const updatedRoleIds = [...this.state.roleIds];
        if (updatedRoleIds.indexOf(roleId) !== -1) {
            updatedRoleIds.splice(updatedRoleIds.indexOf(roleId), 1);
        } else {
            updatedRoleIds.push(roleId);
        }
        this.setState({ roleIds: updatedRoleIds });
    };

    resetState = () => {
        this.setState({ id: '', firstName: '', lastName: '', birthDate: '', gender: 'Select Gender', address: '', email: '', phone: '', username: '', password: '', roleIds: [], roles: [], userRoles: [] });
    };

    onSelectChange = (e, data) => {
        this.setState({ [data.name]: data.value });
    };

    render() {
        const { firstName, lastName, password, birthDate, gender, email, phone, address, username, loading, open, isUpdateDialog, isDeleteDialog, isLockDialog, clearAttemptsDialog, isUnlockDialog, roles, userRoles } = this.state;

        return (
            <Fragment>
                <HeaderContent onClickHandler={() => {
                    this.createUserHandler();
                    this.getRoleList();
                }} buttonTitle={'Create New User'} showButton={true} />
                <Divider />
                <List setRefresh={refresh => this.refreshChild = refresh} parentCallback={this.callbackFunction} />

                <Formik
                    initialValues={{ firstName: firstName, lastName: lastName, password: password, confirmPassword: '', birthDate: birthDate, gender: gender, email: email, address: address, phone: phone, username: username }}
                    onSubmit={(values, { resetForm }) => {
                        this.createNewUserHandler(values, resetForm);
                    }}
                    validationSchema={Yup.object().shape({
                        firstName: Yup.string()
                            .required('This field is required.'),
                        lastName: Yup.string()
                            .required('This field is required.'),
                        password: Yup.string()
                            .required('This field is required.')
                            .min(8, 'Password must be at least 8 characters long.')
                            .matches(RegExp("(.*[a-z].*)"), "Password must include at least one lowercase letter.")
                            .matches(RegExp("(.*[A-Z].*)"), "Password must include at least one uppercase letter.")
                            .matches(RegExp("(.*\\d.*)"), "Password must include at least one number.")
                            .matches(RegExp('[!@#$%^&*(),.?":{}|<>]'), "Password must include at least one special character.")
                        ,
                        confirmPassword: Yup.string()
                            .oneOf([Yup.ref('password'), null], "Passwords don't match!")
                            .required('This field is required.'),
                        birthDate: Yup.string().nullable()
                            .required('This field is required.'),
                        address: Yup.string()
                            .required('This field is required.'),
                        email: Yup.string()
                            .required('This field is required.'),
                        phone: Yup.string()
                            .required('This field is required.'),
                        username: Yup.string()
                            .required('This field is required.')
                    })}
                >
                    {({ handleChange, handleBlur, errors, touched, values, handleSubmit }) => (
                        <Dialog
                            className="user-modal"
                            open={open}
                            onCancel={() => {
                                this.setState({ open: false });
                                this.resetState();
                            }}
                            onConfirm={handleSubmit}
                            title={'Create New User'}
                            confirmText={'Create User'}
                            loading={loading}
                        >
                            <Form>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input fluid label="First Name" type='text' name='firstName' placeholder='First Name' value={values.firstName} onChange={handleChange} onBlur={(e) => {
                                            if (values.firstName && values.lastName) {
                                                values.username = values.firstName.toLowerCase() + '.' + values.lastName.toLowerCase();
                                            }
                                            handleBlur(e);
                                        }} />
                                        {errors.firstName && touched.firstName ? (
                                            <span className='formik-error-msg'>{errors.firstName}</span>
                                        ) : null}
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input fluid label="Last Name" type='text' name='lastName' placeholder='Last Name' value={values.lastName} onChange={handleChange} onBlur={(e) => {
                                            if (values.firstName && values.lastName) {
                                                values.username = values.firstName.toLowerCase() + '.' + values.lastName.toLowerCase();
                                            }
                                            handleBlur(e);
                                        }} />
                                        {errors.lastName && touched.lastName ? (
                                            <span className='formik-error-msg'>{errors.lastName}</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input fluid label="Email" type='email' name='email' placeholder='Email' value={values.email} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.email && touched.email ? (
                                            <span className='formik-error-msg'>{errors.email}</span>
                                        ) : null}
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input fluid label="Birth Date" type='date' name='birthDate' placeholder='Birth Date' value={values.birthDate} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.birthDate && touched.birthDate ? (
                                            <span className='formik-error-msg'>{errors.birthDate}</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input fluid label="Phone" type='text' name='phone' placeholder='Phone' value={values.phone} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.phone && touched.phone ? (
                                            <span className='formik-error-msg'>{errors.phone}</span>
                                        ) : null}
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input fluid label="Address" type='text' name='address' placeholder='Address' value={values.address} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.address && touched.address ? (
                                            <span className='formik-error-msg'>{errors.address}</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>Gender</label>
                                        <Select
                                            placeholder={gender}
                                            name="gender"
                                            options={[
                                                { key: 'male', value: 0, text: 'Male' },
                                                { key: 'female', value: 1, text: 'Female' },
                                            ]}
                                            onChange={this.onSelectChange}
                                            style={{ borderRadius: 0, height: 50 }} />
                                        {gender === "Select Gender" && touched.gender ? (
                                            <span className='formik-error-msg'>This field is required.</span>
                                        ) : null}
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input fluid label="Username" type='text' name='username' placeholder={'Username'} value={values.username} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.username && touched.username ? (
                                            <span className='formik-error-msg'>{errors.username}</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input fluid label="Password" type='password' name='password' placeholder='Password' value={values.password} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.password && touched.password ? (
                                            <span className='formik-error-msg'>{errors.password}</span>
                                        ) : null}
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input fluid label="Confirm Password" type='password' name='confirmPassword' placeholder='Confirm Password' value={values.confirmPassword} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.confirmPassword && touched.confirmPassword ? (
                                            <span className='formik-error-msg'>{errors.confirmPassword}</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                                <HavePermission for="role.list.into.a-portal.actions">
                                    <Form.Group widths='equal'>
                                        {roles.length > 0 ? roles.map((role, index) => {
                                            return (
                                                <Checkbox key={role.id} label={role.name} onChange={() => this.checkboxRoleHandler(role.id)} style={{ marginRight: 15 }} />
                                            )
                                        }) : <div className="spinner-container"><Spinner /></div>}
                                    </Form.Group>
                                </HavePermission>
                            </Form>
                        </Dialog>
                    )}
                </Formik>

                <Formik
                    enableReinitialize
                    initialValues={{ firstName: firstName, lastName: lastName, password: password, confirmPassword: '', birthDate: birthDate ? moment(birthDate).format('YYYY-MM-DD') : birthDate, gender: gender, email: email, address: address, phone: phone, username: username }}
                    onSubmit={values => {
                        this.updateUserHandler(values);
                    }}
                    validationSchema={Yup.object().shape({
                        firstName: Yup.string()
                            .required('This field is required.'),
                        lastName: Yup.string()
                            .required('This field is required.'),
                        password: Yup.string()
                            .min(8, 'Password must be at least 8 characters long.')
                            .matches(RegExp("(.*[a-z].*)"), "Password must include at least one lowercase letter.")
                            .matches(RegExp("(.*[A-Z].*)"), "Password must include at least one uppercase letter.")
                            .matches(RegExp("(.*\\d.*)"), "Password must include at least one number.")
                            .matches(RegExp('[!@#$%^&*(),.?":{}|<>]'), "Password must include at least one special character."),
                        confirmPassword: Yup.string()
                            .oneOf([Yup.ref('password'), null], "Passwords don't match!"),
                        birthDate: Yup.string().nullable()
                            .required('This field is required.'),
                        address: Yup.string()
                            .required('This field is required.'),
                        email: Yup.string()
                            .required('This field is required.'),
                        phone: Yup.string()
                            .required('This field is required.'),
                        username: Yup.string()
                            .required('This field is required.')
                    })}
                >
                    {({ handleChange, handleBlur, errors, touched, values, handleSubmit }) => (
                        <Dialog
                            className="user-modal"
                            open={isUpdateDialog}
                            onCancel={() => {
                                this.setState({ isUpdateDialog: false });
                                this.resetState();
                            }}
                            onConfirm={handleSubmit}
                            title={'Update User'}
                            confirmText={'Update User'}
                            loading={loading}
                        >
                            <Form>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input fluid label="First Name" type='text' name='firstName' placeholder='First Name' value={values.firstName} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.firstName && touched.firstName ? (
                                            <span className='formik-error-msg'>{errors.firstName}</span>
                                        ) : null}
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input fluid label="Last Name" type='text' name='lastName' placeholder='Last Name' value={values.lastName} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.lastName && touched.lastName ? (
                                            <span className='formik-error-msg'>{errors.lastName}</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input fluid label="Email" type='email' name='email' placeholder='Email' value={values.email} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.email && touched.email ? (
                                            <span className='formik-error-msg'>{errors.email}</span>
                                        ) : null}
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input fluid label="Birth Date" type='date' name='birthDate' placeholder='Birth Date' value={values.birthDate} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.birthDate && touched.birthDate ? (
                                            <span className='formik-error-msg'>{errors.birthDate}</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input fluid label="Phone" type='text' name='phone' placeholder='Phone' value={values.phone} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.phone && touched.phone ? (
                                            <span className='formik-error-msg'>{errors.phone}</span>
                                        ) : null}
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input fluid label="Address" type='text' name='address' placeholder='Address' value={values.address} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.address && touched.address ? (
                                            <span className='formik-error-msg'>{errors.address}</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>Gender</label>
                                        <Select
                                            placeholder={gender}
                                            defaultValue={gender}
                                            name="gender"
                                            options={[
                                                { key: 'male', value: 0, text: 'Male' },
                                                { key: 'female', value: 1, text: 'Female' },
                                            ]}
                                            onChange={this.onSelectChange}
                                            style={{ borderRadius: 0, height: 50 }} />
                                        {gender === "Select Gender" && touched.gender ? (
                                            <span className='formik-error-msg'>This field is required.</span>
                                        ) : null}
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input fluid label="Username" type='text' name='username' placeholder={'Username'} value={values.username} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.username && touched.username ? (
                                            <span className='formik-error-msg'>{errors.username}</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input fluid label="Password" type='password' name='password' placeholder='Password' value={values.password} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.password && touched.password ? (
                                            <span className='formik-error-msg'>{errors.password}</span>
                                        ) : null}
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input fluid label="Confirm Password" type='password' name='confirmPassword' placeholder='Confirm Password' value={values.confirmPassword} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.confirmPassword && touched.confirmPassword ? (
                                            <span className='formik-error-msg'>{errors.confirmPassword}</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                                <HavePermission for="role.list.into.a-portal.actions">
                                    <Form.Group widths='equal'>
                                        {roles.map(role => {
                                            return (
                                                <Checkbox key={role.id} label={role.name} onChange={() => this.checkboxRoleHandler(role.id)} style={{ marginRight: 15 }} />
                                            )
                                        })}
                                        {userRoles.map(role => {
                                            return (
                                                <Checkbox key={role.id} label={role.name} defaultChecked onChange={() => this.checkboxRoleHandler(role.id)} style={{ marginRight: 15 }} />
                                            )
                                        })}
                                    </Form.Group>
                                </HavePermission>
                            </Form>
                        </Dialog>
                    )}
                </Formik>


                <Dialog
                    open={isDeleteDialog}
                    onCancel={() => {
                        this.setState({ isDeleteDialog: false });
                        this.resetState();
                    }}
                    onConfirm={this.deleteUserHandler}
                    title={'Delete User'}
                    confirmText={'Delete User'}
                    loading={loading}
                ><p>Are you sure you want to delete this user?</p></Dialog>

                <Dialog
                    open={isLockDialog}
                    onCancel={() => {
                        this.setState({ isLockDialog: false });

                    }}
                    onConfirm={this.lockUserHandler}
                    title={'Lock User'}
                    confirmText={'Lock User'}
                    loading={loading}
                ><p>Are you sure you want to lock this user?</p></Dialog>

                <Dialog
                    open={clearAttemptsDialog}
                    onCancel={() => {
                        this.setState({ clearAttemptsDialog: false });
                        this.resetState();
                    }}
                    onConfirm={this.clearAttemptsHandler}
                    title={'Clear Attempts'}
                    confirmText={'Clear Attempts'}
                    loading={loading}
                ><p>Are you sure you want to clear attempts for this user?</p></Dialog>

                <Dialog
                    open={isUnlockDialog}
                    onCancel={() => {
                        this.setState({ isUnlockDialog: false });
                        this.resetState();
                    }}
                    onConfirm={this.unlockUserHandler}
                    title={'Unlock User'}
                    confirmText={'Unlock User'}
                    loading={loading}
                ><p>Are you sure you want to unlock this user?</p></Dialog>

                <ToastContainer />
            </Fragment>
        );
    }
};

export default withRouter(Users);