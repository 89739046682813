import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Icon, Grid } from 'semantic-ui-react';
import Helper from '../helper';
import HavePermission from './PermissionsService/HavePermission';
import { checkCreatePermission } from './PermissionsService/PermissionsService';

const HeaderContent = props => {
    const [createPermission, setCreatePermission] = useState(null);

    const { pathname } = props.location;

    useEffect(() => {
        const createResult = checkCreatePermission(pathname);
        setCreatePermission(createResult);
    }, [pathname]);

    return (
        <Grid>
            <Grid.Column tablet={16} computer={4}>
                <h2>{Helper.titleCaseForTabs(pathname.substr(1).split("-").join(' '))}</h2>
            </Grid.Column>
            <Grid.Column tablet={16} computer={12} className="buttons-group-col" style={pathname === '/users' ? { paddingRight: 40 } : null}>
                <Button.Group size='mini'>
                    {props.showButton ? (
                        <HavePermission for={createPermission}>
                            <Button icon primary labelPosition='left' onClick={props.onClickHandler}>{props.buttonTitle}<Icon name='plus' /></Button>
                        </HavePermission>) : null}
                </Button.Group>
            </Grid.Column>
        </Grid>
    );
}

export default withRouter(HeaderContent);