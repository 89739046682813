import React from 'react';
import { Form, Dropdown, Ref } from 'semantic-ui-react';
import TableButtons from '../Table/TableButtons'
import Input from '../UI/Input';

class FilterInputs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: []
        };

        this.dropdownItem0 = React.createRef();
        this.dropdownItem1 = React.createRef();
        this.refsArray = [];
        this.refsArray.push(this.dropdownItem0);
        this.refsArray.push(this.dropdownItem1);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filters !== this.props.filters) {
            this.setState({ filters: this.props.filters });
        }
    }

    onChannelChange = (e, data, index) => {
        const newInputs = [...this.state.filters];
        newInputs[index].options.map(option => {
            option.isClicked = false;

            if (option.value === data.value) {
                option.isClicked = true;
            }
        });

        this.setState({ filters: newInputs });
        this.props.onRefreshHandler();
    }

    onChangeHandler = (e, i) => {
        const newInputs = [...this.state.filters];
        newInputs[i].value = e.target.value;
        this.setState({ filters: newInputs });
        document.addEventListener('keypress', (event) => {
            if (event.keyCode === 13 || event.which === 13) {
                this.props.onRefreshHandler();
            }
        });
    }

    clearChannelValue = () => {
        if (this.props.pathname !== '/permissions') {
            var element0 = this.refsArray[0].current.querySelector('[aria-selected="true"]');
            if (element0 && this.dropdownItem0.current.querySelector(".clear")) {
                this.dropdownItem0.current.querySelector(".clear").click();
            }

            var element1 = this.refsArray[1].current.querySelector('[aria-selected="true"]');
            if (element1 && this.dropdownItem1.current.querySelector(".clear")) {
                this.dropdownItem1.current.querySelector(".clear").click();
            }
        }
    };

    render() {
        return (
            <Form size='mini'>
                <Form.Group widths='equal'>
                    {this.state.filters.map((item, index) => {
                        if (item.model === 'select') {
                            return (
                                <Form.Field key={index}>
                                    <label>{item.label}</label>
                                    <Ref innerRef={this.refsArray[index]}>
                                        <Dropdown
                                            placeholder={item.label}
                                            fluid
                                            selection
                                            options={item.options.map(opt => {
                                                return { key: opt.key, value: opt.value, text: opt.text }
                                            })}
                                            onChange={(e, data) => { this.onChannelChange(e, data, index); }}
                                            style={{ borderRadius: 0, height: 50, paddingTop: 17 }}
                                            clearable
                                        />
                                    </Ref>
                                </Form.Field>
                            );
                        } else {
                            return (
                                <Form.Field key={index}>
                                    <Input label={item.label} type={item.type} name={item.label} placeholder={item.placeholder ? item.placeholder : item.label} value={item.value} onChange={(e) => { this.onChangeHandler(e, index) }} />
                                </Form.Field>
                            );
                        }
                    })}
                </Form.Group>
                {this.state.filters.length > 0 ? (
                    <TableButtons
                        clearChannelValue={this.clearChannelValue}
                        refreshHandler={this.props.onRefreshHandler}
                        resetFilters={this.props.onResetFilters}
                        loading={this.props.loading}
                    />
                ) : null}
            </Form>
        );
    }
}

export default FilterInputs;