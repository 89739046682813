import { Table } from 'semantic-ui-react';
import React from 'react';
import { withRouter } from 'react-router-dom';
import Helper from '../../helper';

const TableHeader = (props) => {
  const { pathname } = props.location;
  const d = [...props.data];

  const styleConditioner = (item) => {
    if (item === "id" || item === "permissions" || item === "suspenseAccount" || item === "timeClosesOn" || item === "bank") {
      return {
        display: 'none'
      }
    } else {
      return {};
    }
  }

  return (
    <Table.Header>
      <Table.Row>
        {d.length && pathname !== '/rules' ? Object.keys(d[0]).map((item, index) => {
          return (
            <Table.HeaderCell
              key={index}
              width={2}
              style={styleConditioner(item)}
            >
              {Helper.tableHeadersFormater(item, pathname)}
            </Table.HeaderCell>
          )
        }) : null}
        {pathname !== '/message-history' && pathname !== '/error-management' ? (
          <Table.HeaderCell
            width={1}
          >
            Actions
          </Table.HeaderCell>
        ) : null}
      </Table.Row>
    </Table.Header>
  );
}

export default withRouter(TableHeader);