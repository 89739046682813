import { connect } from 'react-redux';

const HavePermissionsGroup = (props) => {
    const group = props.permissionsGroup;
    const couldShow = props.userPermissions.some(r => group.includes(r));
    if (props.children) {
        return couldShow ? props.children : null;
    }
    return null;
};

const mapStateToProps = state => ({
    userPermissions: state.auth.user['claim.permission']
});

export default connect(mapStateToProps)(HavePermissionsGroup);