import React, { Component, Fragment } from 'react';
import List from "../components/Table/List";
import HeaderContent from "../components/HeaderContent";
import { Divider } from "semantic-ui-react";

class MessageHistory extends Component {
    render() {
        return (
            <Fragment>
                <HeaderContent />
                <Divider />
                <List setRefresh={refresh => this.refreshChild = refresh} parentCallback={this.callbackFunction} />
            </Fragment>
        );
    }
};

export default MessageHistory;