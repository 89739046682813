import React, { useState } from 'react';
import { Form, Button, Icon } from 'semantic-ui-react';
import Input from '../components/UI/Input';
import Logo from '../assets/logo.png';
import BgImage from '../assets/blurred.jpg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config';
import { useDispatch } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { setCurrentUser } from '../store/actions/auth';
import '../assets/css/login.css';
import { Formik } from 'formik';
import * as Yup from 'yup';

const Login = props => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [oldPassword, setOldPassword] = useState(null);
    const [tempToken, setTempToken] = useState(null);

    const dispatch = useDispatch();

    const onLoginHandler = (values) => {
        const { username, password } = values;

        setIsLoading(true);
        setError(null);
        fetch(`${config.baseURL}/account/signin/user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "identifier": username,
                "password": password
            })
        }
        ).then(res => {
            return res.json();
        }).then(data => {
            if (data.success) {
                if(data.value.key === "passwordChanged") {
                    setOldPassword(password);
                    setError(data.value.key);
                    setTempToken(data.value.token);
                } else {
                    localStorage.setItem('token', data.value.token);
                    const decoded = jwt_decode(data.value.token);
                    dispatch(setCurrentUser(decoded));
                    props.history.push('/');
                }
            } else {
                toast.error(data.messages[0].text);
            }
            setIsLoading(false);
        })
    };

    const onPasswordChangeHandler = (values) => {
        const { username, password, confirmPassword } = values;

        setIsLoading(true);
        setError(null);
        fetch(`${config.baseURL}/account/changepassword/user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + tempToken
            },
            body: JSON.stringify({
                "identifier": username,
                "oldPassword": oldPassword,
                "newPassword": password,
                "confirmNewPassword": confirmPassword
            })
        }
        ).then(res => {
            return res.json();
        }).then(data => {
            if (data.success) {
                localStorage.setItem('token', data.value);
                const decoded = jwt_decode(data.value);
                dispatch(setCurrentUser(decoded));
                props.history.push('/');
            } else {
                toast.error(data.messages[0].text);
            }
            setIsLoading(false);
        })
    };

    let schema = Yup.object().shape({
        username: Yup.string()
            .required('This field is required.'),
        password: Yup.string()
            .required('This field is required.')
    });

    if (error === "passwordChanged") {
        schema = Yup.object().shape({
            username: Yup.string()
                .required('This field is required.'),
            password: Yup.string()
                .required('This field is required.')
                .min(8, 'Password must be at least 8 characters long.')
                .matches(RegExp("(.*[a-z].*)"), "Password must include at least one lowercase letter.")
                .matches(RegExp("(.*[A-Z].*)"), "Password must include at least one uppercase letter.")
                .matches(RegExp("(.*\\d.*)"), "Password must include at least one number.")
                .matches(RegExp('[!@#$%^&*(),.?":{}|<>]'), "Password must include at least one special character.")
            ,
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], "Passwords don't match!")
                .required('This field is required.')
        });
    }

    return (
        <div className="view">
            <img src={BgImage} alt="" className="bg-image" />
            <div className="form-container">
                <div className="form-container-header">
                    <img src={Logo} alt="" width={60} />
                    <h4>{error !== 'passwordChanged' ? 'Sign in' : 'Password change required'}</h4>
                </div>


                <Formik
                    initialValues={{ username: '', password: '', confirmPassword: '' }}
                    onSubmit={(values) => {
                        if (error !== "passwordChanged") {
                            onLoginHandler(values);
                        } else {
                            onPasswordChangeHandler(values);
                        }
                    }}
                    validationSchema={schema}
                >
                    {({ handleChange, handleBlur, errors, touched, values, handleSubmit }) => (
                        <Form size='mini'>
                            <Form.Field>
                                <Input type="text" label="Username" name="username" placeholder="Username" value={values.username} onChange={handleChange} onBlur={handleBlur} />
                                {errors.username && touched.username ? (
                                    <span className='login-formik-error-msg'>{errors.username}</span>
                                ) : null}
                            </Form.Field>
                            <Form.Field>
                                <Input type="password" label={error !== 'passwordChanged' ? 'Password' : 'New Password'} name="password" placeholder={error !== 'passwordChanged' ? 'Password' : 'New Password'} value={values.password} onChange={handleChange} onBlur={handleBlur} />
                                {errors.password && touched.password ? (
                                    <span className='login-formik-error-msg'>{errors.password}</span>
                                ) : null}
                            </Form.Field>
                            {error === "passwordChanged" ? (
                                <Form.Field>
                                    <Input type="password" label="Confirm Password" name="confirmPassword" placeholder="Confirm Password" value={values.confirmPassword} onChange={handleChange} onBlur={handleBlur} />
                                    {errors.confirmPassword && touched.confirmPassword ? (
                                        <span className='login-formik-error-msg'>{errors.confirmPassword}</span>
                                    ) : null}
                                </Form.Field>
                            ) : null}

                            {error !== "passwordChanged" ? (
                                <Button loading={isLoading} icon primary labelPosition='right' onClick={() => {
                                    handleSubmit();
                                    setTimeout(() => {
                                        values.password = '';
                                    }, 0);
                                }}>
                                    Login <Icon name='log out' />
                                </Button>
                            ) : (
                                    <Button loading={isLoading} icon primary labelPosition='right' onClick={handleSubmit}>
                                        Change Password <Icon name='log out' />
                                    </Button>
                                )}
                        </Form>
                    )}
                </Formik>
            </div>

            <ToastContainer />
        </div>
    );
};

export default Login;