import React, { Component, Fragment } from 'react';
import config from '../config';
import List from "../components/Table/List";
import HeaderContent from "../components/HeaderContent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Divider, Form } from "semantic-ui-react";
import Dialog from '../components/UI/Dialog';
import { Formik } from 'formik';
import * as Yup from 'yup';

class ErrorManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUpdateDialog: false,
            isDeleteDialog: false,
            loading: false,
            key: '',
            translationAL: '',
            translationEN: '',
            description: ''
        }

        this.callbackFunction = this.callbackFunction.bind(this);
        this.updateError = this.updateError.bind(this);
    };

    callbackFunction(childData, identifier) {
        this.setState({ key: childData.key });
        this.setState({ translationAL: childData.translationAL });
        this.setState({ translationEN: childData.translationEN });
        this.setState({ description: childData.description });

        if (identifier === 'update') {
            this.setState({ isUpdateDialog: true });
        } else if (identifier === 'delete') {
            this.setState({ isDeleteDialog: true });
        }
    };

    updateError(values) {
        const { key, translationAL, translationEN, description } = values;

        this.setState({ loading: true });
        fetch(`${config.adminBaseURL}/localizedresource/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "key": key,
                "translationAL": translationAL,
                "translationEN": translationEN,
                "description": description
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ loading: false });
            if (data.success) {
                this.setState({ key: '' });
                this.setState({ translationAL: '' });
                this.setState({ translationEN: '' });
                this.setState({ description: '' });
                toast.success('Error updated successfully!');
                this.setState({ isUpdateDialog: false });
                this.refreshChild();
            } else {
                toast.error(data.description);
            }
        })
    };

    render() {
        const { key, translationEN, translationAL, description, loading, isUpdateDialog } = this.state;


        return (
            <Fragment>
                <HeaderContent />
                <Divider />
                <List setRefresh={refresh => this.refreshChild = refresh} parentCallback={this.callbackFunction} />

                <Formik
                    enableReinitialize
                    initialValues={{ key: key, translationEN: translationEN, translationAL: translationAL, description: description }}
                    onSubmit={values => {
                        this.updateError(values);
                    }}
                    validationSchema={Yup.object().shape({
                        translationEN: Yup.string()
                            .required('This field is required.'),
                        translationAL: Yup.string()
                            .required('This field is required.')
                    })}
                >
                    {({ handleChange, handleBlur, errors, touched, values, handleSubmit }) => (
                        <Dialog
                            open={isUpdateDialog}
                            onCancel={() => {
                                this.setState({ isUpdateDialog: false });
                                this.setState({ key: '' });
                                this.setState({ translationAL: '' });
                                this.setState({ translationEN: '' });
                                this.setState({ description: '' });
                            }}
                            onConfirm={handleSubmit}
                            title={'Edit Error Details'}
                            confirmText={'Save'}
                            loading={loading}
                        >
                            <Form>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input fluid label="Key" type='text' name='key' placeholder='Key' value={values.key} readOnly />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input fluid label="Translation - EN" type='text' name='translationEN' placeholder='Translation - EN' value={values.translationEN} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.translationEN && touched.translationEN ? (
                                            <span className='formik-error-msg'>{errors.translationEN}</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input fluid label="Translation - AL" type='text' name='translationAL' placeholder='Translation - AL' value={values.translationAL} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.translationAL && touched.translationAL ? (
                                            <span className='formik-error-msg'>{errors.translationAL}</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.TextArea fluid label="Description" type='text' name='description' placeholder='Description' value={values.description} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.description && touched.description ? (
                                            <span className='formik-error-msg'>{errors.description}</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </Dialog>
                    )}
                </Formik>

                <ToastContainer />
            </Fragment>
        );
    }
};

export default ErrorManagement;