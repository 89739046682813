export var list = [
    {
        pathname: '/system-alerts',
        endpoint: '/notificationtemplate/list',
        apiBody: {
            "all": null,
            "status": null,
            "eventType": null,
            "channelSms": null,
            "channelPush": null,
            "channelEmail": null,
        }
    },
    {
        pathname: '/users',
        endpoint: '/user/list',
        apiBody: {
            "status": null,
            "isLocked": null,
            "username": null
        }
    },
    {
        pathname: '/roles',
        endpoint: '/role/list',
        apiBody: {}
    },
    {
        pathname: '/mno-management',
        endpoint: '/mobilenetworkoperator/list',
        apiBody: {}
    },
    {
        pathname: '/error-management',
        endpoint: '/localizedresource/list',
        apiBody: {}
    },
    {
        pathname: '/bank-management',
        endpoint: '/bank/list',
        apiBody: {}
    },
    {
        pathname: '/customer-category',
        endpoint: '/category/list',
        apiBody: {}
    },
    {
        pathname: '/rules',
        endpoint: '/rule/list',
        apiBody: {}
    },
    {
        pathname: '/message-history',
        endpoint: '/report/smssent',
        apiBody: {
            "sentOnStartDate": "2020-01-01"
        }
    },
    {
        pathname: '/permissions',
        endpoint: '/role/permission/list',
        apiBody: {
            "name": null
        }
    }
]