import React, { Component } from 'react';
import Tabs from "react-draggable-tabs";
import { Route, Redirect } from 'react-router-dom';

// Components
import Alert from '../components/Alert.jsx';
import Navbar from '../components/Navbar.jsx';
import Helper from "../helper";
import HavePermission from '../components/PermissionsService/HavePermission';

import { tabs } from './tabs-list';
import { connect } from 'react-redux';
import { setFilters } from '../store/actions/filters';

const WrapperContent = props => {
    return (
        <div className="content-container reports-container">
            <div className="content-area">
                {props.children}
            </div>
        </div>
    );
}

class Layout extends Component {
    constructor(props) {
        super(props);
        this.moveTab = this.moveTab.bind(this);
        this.selectTab = this.selectTab.bind(this);
        this.closedTab = this.closedTab.bind(this);
        this.addTab = this.addTab.bind(this);
        this.state = {
            tabs: [
                {
                    id: 1,
                    content: "Dashboard",
                    active: true,
                    pathname: '/dashboard'
                }
            ]
        };
    }

    componentDidUpdate(prevProps, prevState) {
        this.props.setFilters(this.props.location.pathname);

        if(prevProps.location.state !== this.props.location.state) {
            if(this.props.location.state && this.props.location.state.from === '404') {
                this.setState((state, props) => {
                    let newTabs = [{
                        id: 1,
                        content: "Dashboard",
                        active: true,
                        pathname: '/dashboard'
                    }];

                    return { tabs: newTabs };
                })
            }
        }
    }

    moveTab(dragIndex, hoverIndex) {
        this.setState((state, props) => {
            let newTabs = [...state.tabs]
            newTabs.splice(hoverIndex, 0, newTabs.splice(dragIndex, 1)[0]);

            return { tabs: newTabs };
        });
    }

    selectTab(selectedIndex, selectedID) {
        this.setState((state, props) => {
            const newTabs = state.tabs.map(tab => ({
                ...tab,
                active: tab.id === selectedID
            }));
            newTabs.forEach(item => {
                if (item.id === selectedID) {
                    this.props.history.replace({ pathname: item.pathname })
                }
            })

            return { tabs: newTabs };
        });
    }

    closedTab(removedIndex, removedID) {
        if (this.state.tabs.length <= 1) {
            return;
        } else {
            this.setState((state, props) => {
                let newTabs = [...state.tabs];
                newTabs.splice(removedIndex, 1);

                if (state.tabs[removedIndex].active && newTabs.length !== 0) {
                    // automatically select another tab if needed
                    const newActive = removedIndex === 0 ? 0 : removedIndex - 1;
                    newTabs[newActive].active = true;
                    this.props.history.replace({ pathname: newTabs[newActive].pathname })
                }

                return { tabs: newTabs };
            });
        }
    }

    addTab(e) {
        let content = e.target.textContent;

        this.setState((state, props) => {
            let newTabs = [...state.tabs];

            let exists = false;
            newTabs.forEach(item => {
                item.active = false;
                if (item.content === content && !exists) {
                    item.active = true;
                    exists = true;
                }
            });

            if (!exists) {
                newTabs.push({
                    id: content,
                    content: content,
                    active: true,
                    pathname: content.replace(/\s/g, '-').toLowerCase()
                });
            }

            return { tabs: newTabs };
        });
    }

    render() {
        return (
            <div className="main-container">
                {/*<Alert />*/}
                <Navbar addTab={this.addTab} user={this.props.user} />
                <div>
                    <Tabs
                        moveTab={this.moveTab}
                        selectTab={this.selectTab}
                        closeTab={this.closedTab}
                        tabs={this.state.tabs}
                    />
                    <Route path='/dashboard' render={() => (
                        <div />
                    )} />

                    {tabs.map(tab => {
                        return (
                            <HavePermission for={tab.permissionName} key={tab.path}>
                                <Route key={tab.path} path={tab.path} render={() => {
                                    return <WrapperContent>{tab.component}</WrapperContent>;
                                }} />
                            </HavePermission>
                        )
                    })}

                    <Redirect to={{
                        pathname: '/dashboard',
                        state: { from: '404' }
                    }} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps, {
    setFilters
})(Layout);