import React from 'react';

const Sidemenu = props => {
    let content;

    if (props.user) {
        content = (
            <div>
                <p>First name:</p>
                <h4>{props.user.firstName}</h4>
                <p>Last name:</p>
                <h4>{props.user.lastName}</h4>
                <p>Birth Date:</p>
                <h4>{props.user.birthDate}</h4>
            </div>
        )
    } else {
        content = <h4>Please select an user from the table to see basic info.</h4>
    }

    return (
        <div className={`sidemenu ${props.visible ? 'opened' : ''}`}>
            {content}
        </div>
    );
};

export default Sidemenu;