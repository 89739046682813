import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const UnauthenticatedRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                !localStorage.token ? (
                    <Component {...props} />
                ) : (
                        <Redirect to="/dashboard" />
                    )
            }
        />
    )
};

export default UnauthenticatedRoute;