import { filterInputsList } from '../../components/Filters/filter-inputs'

export const SET_FILTERS = 'SET_FILTERS';

export const setFilters = (pathname) => dispatch => {
    filterInputsList.map(filterInput => {
        if (filterInput.pathname === pathname) {
            dispatch({
                type: SET_FILTERS,
                payload: filterInput.filters
            })
        }
    })
}
