import { permissionsList } from './permissions-list';

export const checkUpdatePermission = pathname => {
    let value;
    permissionsList.updatePermissions.map(p => {
        if (p.path === pathname) {
            value = p.permission
        }
    });

    return value;
};

export const checkDeletePermission = pathname => {
    let value;
    permissionsList.deletePermissions.map(p => {
        if (p.path === pathname) {
            value = p.permission
        }
    });

    return value;
};

export const checkCreatePermission = pathname => {
    let value;
    permissionsList.createPermissions.map(p => {
        if (p.path === pathname) {
            value = p.permission
        }
    });

    return value;
};