import React, { Component, Fragment } from 'react';
import config from '../config';
import List from "../components/Table/List";
import HeaderContent from "../components/HeaderContent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Divider, Form, Checkbox } from "semantic-ui-react";
import Dialog from '../components/UI/Dialog';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { displayErrors } from '../helper';

class Roles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            isUpdateDialog: false,
            isDeleteDialog: false,
            loading: false,
            id: '',
            name: '',
            description: '',
            permissions: [],
            data: [],
            roleIds: []
        }

        this.callbackFunction = this.callbackFunction.bind(this);
        this.createRoleHandler = this.createRoleHandler.bind(this);
        this.createNewRoleHandler = this.createNewRoleHandler.bind(this);
        this.updateRoleHandler = this.updateRoleHandler.bind(this);
        this.deleteRoleHandler = this.deleteRoleHandler.bind(this);
    };

    callbackFunction(childData, identifier) {
        this.setState({ id: childData.id });
        this.setState({ name: childData.name });
        this.setState({ description: childData.description });
        this.setState({ permissions: childData.permissions });

        if (identifier === 'update') {
            this.setState({ isUpdateDialog: true });
        } else if (identifier === 'delete') {
            this.setState({ isDeleteDialog: true });
        }
    };

    getPermissionDataHandler = (value) => {
        this.setState({ data: value });
    }

    createRoleHandler() {
        this.setState({ open: true });
    };

    createNewRoleHandler(values) {
        const { name, description } = values;

        this.setState({ loading: true });
        fetch(`${config.adminBaseURL}/role/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "name": name,
                "description": description,
                "roleIds": this.state.roleIds
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ loading: false });
            if (data.success) {
                this.setState({ open: false });
                this.setState({ name: '' });
                this.setState({ description: '' });
                this.setState({ permissions: [] });
                toast.success('Role created successfully!');
                this.refreshChild();
            } else {
                displayErrors(data, toast);
            }
        })
    };


    updateRoleHandler(values) {
        const { name, description } = values;
        const { id } = this.state;

        this.setState({ loading: true });
        fetch(`${config.adminBaseURL}/role/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "id": id,
                "name": name,
                "description": description,
                "roleIds": this.state.roleIds
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ loading: false });
            if (data.success) {
                this.setState({ isUpdateDialog: false });
                this.setState({ id: '' });
                this.setState({ name: '' });
                this.setState({ description: '' });
                this.setState({ permissions: [] });
                toast.success('Role updated successfully!');
                this.refreshChild();
            } else {
                displayErrors(data, toast);
            }
        })
    };


    deleteRoleHandler() {
        this.setState({ loading: true });
        fetch(`${config.adminBaseURL}/role/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                "id": this.state.id
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            this.setState({ loading: false });
            if (data.success) {
                this.setState({ isDeleteDialog: false });
                this.setState({ id: '' });
                this.setState({ name: '' });
                this.setState({ description: '' });
                this.setState({ permissions: [] });
                toast.success('Role deleted successfully!');
                this.refreshChild();
            } else {
                toast.error(data.description);
            }
        })
    };

    render() {
        const { name, description, loading, open, isUpdateDialog, isDeleteDialog, data, roleIds } = this.state;

        return (
            <Fragment>
                <HeaderContent onClickHandler={this.createRoleHandler} buttonTitle={'Create New Role'} showButton={true} />
                <Divider />
                <List setRefresh={refresh => this.refreshChild = refresh} parentCallback={this.callbackFunction} getPermissionData={this.getPermissionDataHandler} />

                <Formik
                    initialValues={{ name: name, description: description }}
                    onSubmit={(values, { resetForm }) => {
                        this.createNewRoleHandler(values);
                        resetForm();
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .required('This field is required.'),
                        description: Yup.string()
                            .required('This field is required.')
                            .min(5, 'The length of description must be at least 5 characters')
                    })}
                >
                    {({ handleChange, handleBlur, errors, touched, values, handleSubmit }) => (
                        <Dialog
                            open={open}
                            onCancel={() => {
                                this.setState({ open: false })
                            }}
                            onConfirm={handleSubmit}
                            title={'Create New Role'}
                            confirmText={'Create Role'}
                            loading={loading}
                        >
                            <Form>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input fluid label="Name" type='text' name='name' placeholder='Name' value={values.name} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.name && touched.name ? (
                                            <span className='formik-error-msg'>{errors.name}</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input fluid label="Description" type='text' name='description' placeholder='Description' value={values.description} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.description && touched.description ? (
                                            <span className='formik-error-msg'>{errors.description}</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    {data.map((role, index) => {
                                        return (
                                            <Checkbox key={role.id} label={role.name} onChange={(e) => {
                                                const updatedRoleIds = [...roleIds];
                                                if (updatedRoleIds.indexOf(role.id) !== -1) {
                                                    updatedRoleIds.splice(updatedRoleIds.indexOf(role.id), 1);
                                                } else {
                                                    updatedRoleIds.push(role.id);
                                                }
                                                this.setState({ roleIds: updatedRoleIds });
                                            }} style={{ marginRight: 15 }} />
                                        )
                                    })}
                                </Form.Group>
                            </Form>
                        </Dialog>
                    )}
                </Formik>

                <Formik
                    enableReinitialize
                    initialValues={{ name: name, description: description }}
                    onSubmit={values => {
                        this.updateRoleHandler(values);
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .required('This field is required.'),
                        description: Yup.string()
                            .required('This field is required.')
                            .min(5, 'The length of description must be at least 5 characters')
                    })}
                >
                    {({ handleChange, handleBlur, errors, touched, values, handleSubmit }) => (
                        <Dialog
                            open={isUpdateDialog}
                            onCancel={() => {
                                this.setState({ isUpdateDialog: false });
                                this.setState({ id: '' });
                                this.setState({ name: '' });
                                this.setState({ description: '' });
                                this.setState({ permissions: [] });
                            }}
                            onConfirm={handleSubmit}
                            title={'Update Role'}
                            confirmText={'Update Role'}
                            loading={loading}
                        >
                            <Form>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input fluid label="Name" type='text' name='name' placeholder='Name' value={values.name} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.name && touched.name ? (
                                            <span className='formik-error-msg'>{errors.name}</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <Form.Input fluid label="Description" type='text' name='description' placeholder='Description' value={values.description} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.description && touched.description ? (
                                            <span className='formik-error-msg'>{errors.description}</span>
                                        ) : null}
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    {data.map((role, index) => {
                                        return (
                                            <Checkbox key={role.id} label={role.name} onChange={() => {
                                                const updatedRoleIds = [...roleIds];
                                                if (updatedRoleIds.indexOf(role.id) !== -1) {
                                                    updatedRoleIds.splice(updatedRoleIds.indexOf(role.id), 1);
                                                } else {
                                                    updatedRoleIds.push(role.id);
                                                }
                                                this.setState({ roleIds: updatedRoleIds });
                                            }} style={{ marginRight: 15 }} />
                                        )
                                    })}
                                </Form.Group>
                            </Form>
                        </Dialog>
                    )}
                </Formik>

                <Dialog
                    open={isDeleteDialog}
                    onCancel={() => {
                        this.setState({ isDeleteDialog: false });
                        this.setState({ id: '' });
                        this.setState({ name: '' });
                        this.setState({ description: '' });
                    }}
                    onConfirm={this.deleteRoleHandler}
                    title={'Delete Role'}
                    confirmText={'Delete Role'}
                    loading={loading}
                ><p>Are you sure you want to delete this role?</p></Dialog>

                <ToastContainer />
            </Fragment>
        );
    }
};

export default Roles;