import React, { Component } from 'react';
import { Button, Grid } from 'semantic-ui-react';

class TableButtons extends Component {
    resetHandler = () => {
        this.props.resetFilters();
        this.props.clearChannelValue();
    }

    render() {
        return (
            <Grid>
                <Grid.Column tablet={16} computer={10}>
                    <Button size='mini' loading={this.props.loading} primary onClick={this.props.refreshHandler}><i className="fas fa-sync"></i> Refresh Table</Button>
                </Grid.Column>
                <Grid.Column tablet={16} computer={6} className="buttons-group-col-2">
                    <Button.Group size='mini'>
                        <Button onClick={this.resetHandler}>Reset</Button>
                        <Button.Or />
                        <Button primary onClick={this.props.refreshHandler}>Filter Now</Button>
                    </Button.Group>
                </Grid.Column>
            </Grid>
        );
    }
}

export default TableButtons;